import { Observable, Subject } from 'rxjs';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EnforcerService } from '@app/casbin/services/enforcer.service';
import { PermissionObjectAction } from '@app/core/models/auth.model';
import { ArticleUserMode, ArticleUserModeAbbreviationMapping } from './article-role.models';
import { YdocService } from '@app/editor/services/ydoc.service';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'awt-article-role',
  templateUrl: './article-role.component.html',
  styleUrls: ['./article-role.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleRoleComponent implements OnInit, OnDestroy {
  @Input() permissionChange$: Observable<string>;

  userAccess$: Observable<string>;

  icon$ = new Subject<string>();

  details$ = new Subject<string>();

  role$ = new Subject<string>();

  private editModePermissions: PermissionObjectAction<'editMode'>[];

  private commentPermissions: PermissionObjectAction<'comments'>[];

  private unsubscribe$ = new Subject<void>();

  private readonly errorMessage = 'No proper article role was retrieved!';

  private readonly modeMap: ArticleUserModeAbbreviationMapping = {
    [ArticleUserMode.canCommentOnly]: {
      short: 'CO',
      details: 'Comment only mode: You can only add comments to this article.',
      icon: 'comments',
    },
    [ArticleUserMode.editor]: {
      short: 'ED',
      details: 'Editor mode: You have full access to modify this article.',
      icon: 'edit1',
    },
    [ArticleUserMode.readOnly]: {
      short: 'RO',
      details: 'Read only mode: You can only view this article.',
      icon: 'visibility',
    },
    [ArticleUserMode.suggester]: {
      short: 'SG',
      details: 'Suggestion mode: You can make suggestions about the article.',
      icon: 'drive_file_rename_outline',
    },
  };

  constructor(
    private enforcerService: EnforcerService,
    private ydocService: YdocService
  ) {}

  get shortRoleName(): string {
    return this.modeMap[this.role].short;
  }

  private get role(): ArticleUserMode {
    if (this.isEditor) {
      return ArticleUserMode.editor;
    } else if (this.isSuggester) {
      return ArticleUserMode.suggester;
    } else if (this.canCommentOnly) {
      return ArticleUserMode.canCommentOnly;
    } else if (!this.isReadOnly) {
      this.riseError();
      return null;
    }

    return ArticleUserMode.readOnly;
  }

  private get isEditor(): boolean {
    return this.editModePermissions.includes('edit');
  }

  private get isSuggester(): boolean {
    return (
      !this.editModePermissions.includes('edit') && this.editModePermissions.includes('suggest')
    );
  }

  private get canCommentOnly(): boolean {
    const { editModePermissions, commentPermissions } = this;
    const canPreview = editModePermissions.includes('preview');
    const canPreviewOnly = editModePermissions.length === 1 && canPreview;
    const canComment = commentPermissions.includes('create');
    return canPreviewOnly && canComment;
  }

  private get isReadOnly(): boolean {
    return (
      !this.commentPermissions.includes('create') &&
      this.editModePermissions.length === 1 &&
      this.editModePermissions[0] === 'preview'
    );
  }

  ngOnInit(): void {
    this.watchPermissionChange();
    this.userAccess$ = this.ydocService.currentUserAccess$.pipe(
      tap(() => this.enforcerService.triggerUpdatePolicy())
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private watchPermissionChange(): void {
    this.permissionChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.assignPermissions());
  }

  private assignPermissions(): void {
    const articleUuid = this.ydocService.articleData.uuid;
    this.editModePermissions = this.enforcerService.getAllowedActionsPerArticle(
      'editMode',
      articleUuid
    );
    this.commentPermissions = this.enforcerService.getAllowedActionsPerArticle(
      'comments',
      articleUuid
    );

    // Only emit if we have a valid role
    const currentRole = this.role;
    if (currentRole) {
      this.icon$.next(this.modeMap[currentRole].icon);
      this.details$.next(this.modeMap[currentRole].details);
      this.role$.next(currentRole);
    }
  }

  private riseError(): void {
    console.error(this.errorMessage);
  }
}
