import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { EditorState, Plugin, PluginKey, TextSelection, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

import { RefsInArticleDialogComponent } from '@app/editor/dialogs/refs-in-article-dialog/refs-in-article-dialog.component';
import { RefsInArticleCiteDialogComponent } from '@app/editor/dialogs/refs-in-article-cite-dialog/refs-in-article-cite-dialog.component';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { FiguresDialogComponent } from '@app/editor/dialogs/figures-dialog/figures-dialog.component';
import { SupplementaryFilesDialogComponent } from '@app/editor/dialogs/supplementary-files/supplementary-files.component';
import { EndNotesDialogComponent } from '@app/editor/dialogs/end-notes/end-notes.component';
import { CitableTablesDialogComponent } from '@app/editor/dialogs/citable-tables-dialog/citable-tables-dialog.component';
import { ReferenceEditComponent } from '@app/layout/pages/library/reference-edit/reference-edit.component';
import { generateNewReference } from '@app/layout/pages/library/lib-service/refs-funcs';
import { harvardStyle } from '@app/layout/pages/library/lib-service/csl.service';
import { citationElementMap } from '@app/editor/services/citable-elements.service';
import { EditorsRefsManagerService } from '@app/layout/pages/library/lib-service/editors-refs-manager.service';
import { RefsApiService } from '@app/layout/pages/library/lib-service/refs-api.service';

let hoverTimer: NodeJS.Timeout;
let isIn = false;

@Injectable({
  providedIn: 'root',
})
export class CitationButtonsService {
  citationButtonsPluginKey = new PluginKey('citationButtonsPlugin');
  citationButtonsPlugin: Plugin;
  citationElementsNodeNames = [
    'citation',
    'supplementary_file_citation',
    'table_citation',
    'end_note_citation',
    'reference_citation',
  ];

  citableElementsDialogs = {
    citation: FiguresDialogComponent,
    supplementary_file_citation: SupplementaryFilesDialogComponent,
    table_citation: CitableTablesDialogComponent,
    end_note_citation: EndNotesDialogComponent,
  };

  citationNodeNamesAndButtonsTitles = {
    citation: 'Edit Figures',
    supplementary_file_citation: 'Edit Supplementary Files',
    table_citation: 'Edit Tables',
    end_note_citation: 'Edit Endnotes',
  };

  constructor(
    private serviceShare: ServiceShare,
    private refsAPI: RefsApiService,
    private editorsRefsManager: EditorsRefsManagerService,
    private dialog: MatDialog
  ) {
    const self = this;
    this.citationButtonsPlugin = new Plugin({
      key: this.citationButtonsPluginKey,
      state: {
        init: (config: any, _: EditorState) => {
          return {
            sectionName: config.sectionName,
            editorType: config.editorType ? config.editorType : undefined,
          };
        },
        apply: (transaction: Transaction, value, _, newState) => {
          return value;
        },
      },
      props: {
        handleDOMEvents: {
          mouseover: (view, event) => {
            const targetElement = event.target as HTMLElement;
            if (targetElement.matches('reference-citation[tooltip]')) {
              hoverTimer = setTimeout(() => {
                const rect = targetElement.getBoundingClientRect();
                const topOffset = rect.bottom + window.scrollY;
                const leftOffset = rect.left + window.scrollX;
                let tooltipDiv = document.querySelector('.tooltip-placeholder') as HTMLDivElement;

                if (!tooltipDiv) {
                  tooltipDiv = document.createElement('div');
                } else {
                  tooltipDiv.innerHTML = '';
                }

                const texts = targetElement.attributes.getNamedItem('tooltip').value.split('\n');
                texts.forEach((t) => {
                  const p = document.createElement('p');
                  p.classList.add('tooltip-content');
                  p.textContent = t;
                  tooltipDiv.append(p);
                });

                tooltipDiv.style.top = `${topOffset}px`;
                tooltipDiv.style.left = `${leftOffset}px`;
                tooltipDiv.classList.add('tooltip-placeholder');
                document.body.appendChild(tooltipDiv);
              }, 1000); // 1 second delay
            }
          },
          mouseout: (view, event) => {
            clearTimeout(hoverTimer);

            const tooltipDiv = document.querySelector('.tooltip-placeholder');
            if (tooltipDiv) {
              tooltipDiv.addEventListener('mouseover', () => {
                isIn = true;
              });
              tooltipDiv.addEventListener('mouseout', () => {
                const targetElement = event.target as HTMLElement;
                if (!targetElement.matches('reference-citation[tooltip]')) {
                  isIn = false;
                }
              });

              let interval = setInterval(() => {
                if (!isIn || !document.querySelector('.tooltip-placeholder:hover')) {
                  tooltipDiv.remove();
                  clearInterval(interval);
                }
              }, 100);
            }
          },
        },
      },
      view: function (view: EditorView) {
        return {
          update: (view: EditorView, prevState) => {
            if (
              JSON.stringify(view.state.doc) == JSON.stringify(prevState.doc) &&
              !view.hasFocus()
            ) {
              return;
            }
            self.attachCitationRefButtons(view);
            let { from, to } = view.state.selection;
            if (from == to) {
              const $pos = view.state.doc.resolve(from);
              const { parent, parentOffset } = $pos;
              const { node, offset } = parent.childAfter(parentOffset);
              if (!node) return;
              if (
                !node.marks ||
                !node.marks.find((mark) => self.citationElementsNodeNames.includes(mark.type.name))
              ) {
                return;
              }

              const start = $pos.start() + offset;
              const end = start + node.nodeSize;
              if (start !== from) {
                let tr = view.state.tr.setSelection(
                  TextSelection.create(view.state.doc, start, end)
                );
                view.dispatch(tr);
              }
            }
          },
          destroy: () => {},
        };
      },
    });
  }

  attachCitationRefButtons(view: EditorView) {
    const anchor = view.state.selection.$anchor;
    const citationInfo = this.findCitationMark(view, anchor.pos);
    const { from, to } = view.state.selection;

    const btnsWrapper = document.getElementsByClassName(
      'editor_buttons_wrapper'
    )[0] as HTMLDivElement;
    if (!btnsWrapper) return;
    const editCitableElementsContainer = btnsWrapper.getElementsByClassName(
      'citable-items-edit-btn-container'
    )[0] as HTMLDivElement;
    const editCitationBtnContainer = btnsWrapper.getElementsByClassName(
      'edit-cite-ref-btn-container'
    )[0] as HTMLDivElement;
    const deleteCitationBtnContainer = btnsWrapper.getElementsByClassName(
      'delete-citation-btn-container'
    )[0] as HTMLDivElement;

    if (!editCitableElementsContainer) return;

    if (citationInfo?.mark && citationInfo.mark.type.name != 'reference_citation' && from != to) {
      editCitableElementsContainer.style.display = 'block';
      deleteCitationBtnContainer.style.display = 'block';
      editCitationBtnContainer.style.display = 'none';

      const editCitableElementsButton = editCitableElementsContainer.getElementsByClassName(
        'edit-citable-button'
      )[0] as HTMLButtonElement;
      const deleteCitationBtn = deleteCitationBtnContainer.getElementsByClassName(
        'delete-citation-btn'
      )[0] as HTMLButtonElement;

      editCitableElementsButton.title =
        this.citationNodeNamesAndButtonsTitles[citationInfo.mark.type.name];

      deleteCitationBtn.removeAllListeners!('click');
      editCitableElementsButton.removeAllListeners('click');

      editCitableElementsButton.addEventListener('click', (event: MouseEvent) => {
        if (citationInfo.mark.attrs.citated_elements.length > 1) {
          this.dialog.open(this.citableElementsDialogs[citationInfo.mark.type.name] as any, {
            data: {},
            disableClose: false,
          });
        } else {
          const markName = citationInfo.mark.type.name;
          const elementMap = citationElementMap[markName];
          const props = {
            citation: ['fig', 'figID', 'figureID'],
            table_citation: ['table', 'tableID', 'tableID'],
            supplementary_file_citation: [
              'supplementaryFile',
              'supplementaryFileID',
              'supplementary_file_ID',
            ],
            end_note_citation: ['endNote', 'endNoteID', 'end_note_ID'],
          };
          const elId = citationInfo.mark.attrs.citated_elements[0];
          const elements = JSON.parse(
            JSON.stringify(
              this.serviceShare.YdocService[elementMap.yjsMap].get(elementMap.elementsObj)
            )
          );
          const elementNumbers = JSON.parse(
            JSON.stringify(
              this.serviceShare.YdocService[elementMap.yjsMap].get(elementMap.elementNumbersObj)
            )
          ) as string[];
          const elIndex = elementNumbers.findIndex((id) => id == elId);
          this.dialog
            .open(elementMap.editModal, {
              data: {
                [props[markName][0]]: elements[elId],
                updateOnSave: false,
                index: elIndex,
                [props[markName][1]]: elements[elId][props[markName][2]],
              },
              disableClose: false,
            })
            .afterClosed()
            .subscribe((result: any) => {
              if (result) {
                if (result.figure) {
                  elementNumbers.splice(elIndex, 1, result.figure.figureID);
                  elements[result.figure.figureID] = result.figure;
                  this.serviceShare.CitableElementsService.writeElementDataGlobal(
                    elements,
                    elementNumbers,
                    'citation'
                  );
                } else if (result.table) {
                  const editMode = result.table.editMode;
                  elementNumbers.splice(elIndex, 1, result.table.tableID);
                  elements[result.table.tableID] = result.table;
                  Object.keys(elements).forEach((key) => {
                    this.serviceShare.CitableElementsService.deletedTablesForRerender[key] =
                      citationElementMap['table_citation'].getElFormIOSubmission(
                        elements[key],
                        undefined,
                        this.serviceShare
                      );
                  });
                  this.serviceShare.CitableElementsService.writeElementDataGlobal(
                    elements,
                    elementNumbers,
                    'table_citation',
                    editMode
                  );
                } else if (result.supplementaryFile) {
                  elementNumbers.splice(elIndex, 1, result.supplementaryFile.supplementary_file_ID);
                  elements[result.supplementaryFile.supplementary_file_ID] =
                    result.supplementaryFile;
                  this.serviceShare.CitableElementsService.writeElementDataGlobal(
                    elements,
                    elementNumbers,
                    'supplementary_file_citation'
                  );
                } else if (result.endNote) {
                  elementNumbers.splice(elIndex, 1, result.endNote.end_note_ID);
                  elements[result.endNote.end_note_ID] = result.endNote;
                  this.serviceShare.CitableElementsService.writeElementDataGlobal(
                    elements,
                    elementNumbers,
                    'end_note_citation'
                  );
                }
              }
            });
        }
        btnsWrapper.style.display = 'none';
      });

      deleteCitationBtn.addEventListener('click', (event: MouseEvent) => {
        const mark = citationInfo.mark;
        const data = JSON.parse(JSON.stringify(mark.attrs));
        const citationId = data.citateid;
        let nodePos: number;
        let nodeSize: number;
        let found = false;
        const docSize = view.state.doc.content.size;
        view.state.doc.nodesBetween(0, docSize - 2, (node, pos) => {
          let mark = node.marks.find((mark) => mark.type.name == mark.type.name);
          if (mark && mark.attrs.citateid == citationId) {
            nodePos = pos;
            nodeSize = node.nodeSize;
            found = true;
          }
        });
        if (found) {
          view.focus();
          const tr = view.state.tr.deleteRange(nodePos, nodePos + nodeSize);
          tr.setSelection(TextSelection.create(tr.doc, from));
          view.dispatch(tr);
        }

        btnsWrapper.style.display = 'none';
      });
      return;
    } else if (
      citationInfo?.mark &&
      citationInfo.mark.type.name == 'reference_citation' &&
      from != to
    ) {
      const editReferenceItemBtn = editCitableElementsContainer.getElementsByClassName(
        'edit-citable-button'
      )[0] as HTMLButtonElement;
      const editCitationBtn = editCitationBtnContainer.getElementsByClassName(
        'edit-cite-ref-button'
      )[0] as HTMLButtonElement;
      const deleteCitationBtn = deleteCitationBtnContainer.getElementsByClassName(
        'delete-citation-btn'
      )[0] as HTMLButtonElement;

      editReferenceItemBtn.title = 'Edit References';
      editCitableElementsContainer.style.display = 'block';
      editCitationBtnContainer.style.display = 'block';
      deleteCitationBtnContainer.style.display = 'block';

      editReferenceItemBtn.removeAllListeners!('click');
      editCitationBtn.removeAllListeners!('click');
      deleteCitationBtn.removeAllListeners!('click');

      editReferenceItemBtn.addEventListener('click', (event: MouseEvent) => {
        if (citationInfo.mark.attrs.citedRefsIds.length > 1) {
          this.dialog.open(RefsInArticleDialogComponent, {
            data: {},
            disableClose: false,
          });
        } else {
          const citedRef = citationInfo.mark.attrs.citedRefsIds[0];
          const refsInYdoc =
            this.serviceShare.YdocService.referenceCitationsMap.get('refsAddedToArticle');

          const ref = refsInYdoc[citedRef];
          this.editorsRefsManager.editRef = true;
          this.serviceShare.ProsemirrorEditorsService.spinSpinner();
          this.refsAPI.getReferenceTypes().subscribe((refTypes: any) => {
            this.refsAPI.getStyles().subscribe((refStyles: any) => {
              let referenceStyles = refStyles.data;
              let referenceTypesFromBackend = refTypes.data;
              let oldData = {
                refData: { formioData: ref.formIOData },
                refType: ref.refType,
                refStyle: ref.refStyle,
                refCiTO: null,
              };

              const dialogRef = this.dialog.open(ReferenceEditComponent, {
                data: { referenceTypesFromBackend, oldData, referenceStyles, editMode: true },
                panelClass: ['edit-reference-panel', 'editor-dialog-container'],
                disableClose: this.editorsRefsManager.closeOnClickOutside,
              });

              this.serviceShare.ProsemirrorEditorsService.stopSpinner();
              dialogRef.afterClosed().subscribe((result: any) => {
                if (result) {
                  let newRef = generateNewReference(
                    result.referenceScheme,
                    result.submissionData.data
                  );
                  let refObj = { ref: newRef, formIOData: result.submissionData.data };
                  let refStyle;
                  if (
                    this.serviceShare.YdocService.articleData &&
                    this.serviceShare.YdocService.articleData.layout.citation_style
                  ) {
                    let style = this.serviceShare.YdocService.articleData.layout.citation_style;
                    refStyle = {
                      name: style.name,
                      label: style.title,
                      style: style.style_content,
                      last_modified: new Date(style.style_updated).getTime(),
                    };
                  } else {
                    refStyle = {
                      name: 'harvard-cite-them-right',
                      label: 'Harvard Cite Them Right',
                      style: harvardStyle,
                      last_modified: 1649665699315,
                    };
                  }
                  refObj.ref.id = citedRef;
                  let refBasicCitation: any = this.serviceShare.CslService.getBasicCitation(
                    refObj.ref,
                    refStyle.style
                  );
                  let container = document.createElement('div');
                  container.innerHTML = refBasicCitation.bibliography;
                  refBasicCitation.textContent = container.textContent;
                  let refInstance = {
                    ...refObj,
                    citation: refBasicCitation,
                    refType: result.referenceScheme,
                    ref_last_modified: Date.now(),
                    refCiTO: result.refCiTO,
                    refStyle,
                  };
                  let refId = citedRef;
                  let allRefs =
                    this.serviceShare.YdocService.referenceCitationsMap.get('refsAddedToArticle');
                  allRefs[refId] = refInstance;
                  if (
                    this.serviceShare.compareObjects(
                      this.serviceShare.YdocService.referenceCitationsMap.get('refsAddedToArticle'),
                      allRefs
                    )
                  ) {
                    this.serviceShare.YdocService.referenceCitationsMap.set(
                      'refsAddedToArticle',
                      allRefs
                    );
                  }
                  this.editorsRefsManager.updateRefsInEndEditorAndTheirCitations();
                }
              });
            });
          });
        }
      });

      editCitationBtn.addEventListener('click', (event: MouseEvent) => {
        const { from, mark: referenceCitation, node } = citationInfo;
        const { citedRefsCiTOs, citedRefsIds, refCitationID } = referenceCitation.attrs;
        const referenceCitations =
          this.serviceShare.YdocService.referenceCitationsMap.get('referenceCitations');
        const citedRefsInArticle =
          this.serviceShare.YdocService.referenceCitationsMap.get('citedRefsInArticle');
        const data = this.setData(
          referenceCitations,
          node,
          refCitationID,
          citedRefsIds,
          citedRefsCiTOs
        );

        this.dialog
          .open(RefsInArticleCiteDialogComponent, {
            panelClass: 'editor-dialog-container',
            data,
            width: '680px',
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              const { citation } = result as {
                citation: {
                  text: string;
                  refCitationIDs: string[];
                  citationLayout: number;
                  sortOptions: any[];
                };
              };

              citedRefsIds.forEach((refId: string) => {
                if (citedRefsInArticle[refId] > 1) {
                  citedRefsInArticle[refId]--;
                } else {
                  delete citedRefsInArticle[refId];
                }
              });
              if (
                this.serviceShare.compareObjects(
                  this.serviceShare.YdocService.referenceCitationsMap.get('citedRefsInArticle'),
                  citedRefsInArticle
                )
              ) {
                this.serviceShare.YdocService.referenceCitationsMap.set(
                  'citedRefsInArticle',
                  citedRefsInArticle
                );
              }

              const citationObj = {
                citedRefsIds,
                citationNodeSize: node.nodeSize,
                citationPos: from,
                refCitationID,
                otherMarks: node.marks.filter((m) => m.type.name != 'reference_citation'),
              };

              this.editorsRefsManager.citateSelectedReferencesInEditor(citation, view, citationObj);
            }
            btnsWrapper.style.display = 'none';
          });
      });

      deleteCitationBtn.addEventListener('click', (event: MouseEvent) => {
        const { from, mark: referenceCitation, node } = citationInfo;
        const citedRefsInArticle =
          this.serviceShare.YdocService.referenceCitationsMap.get('citedRefsInArticle');
        const referenceCitations =
          this.serviceShare.YdocService.referenceCitationsMap.get('referenceCitations');

        view.focus();
        const tr = view.state.tr.deleteRange(from, from + node.nodeSize);
        tr.setSelection(TextSelection.create(tr.doc, from));
        view.dispatch(tr.setMeta('deleteRefCitation', true));

        referenceCitation.attrs.citedRefsIds.forEach((refId: string) => {
          if (citedRefsInArticle[refId] > 1) {
            citedRefsInArticle[refId]--;
          } else {
            delete citedRefsInArticle[refId];
          }
        });
        delete referenceCitation[referenceCitation.attrs.refCitationID];

        if (
          this.serviceShare.compareObjects(
            this.serviceShare.YdocService.referenceCitationsMap.get('citedRefsInArticle'),
            citedRefsInArticle
          )
        ) {
          this.serviceShare.YdocService.referenceCitationsMap.set(
            'citedRefsInArticle',
            citedRefsInArticle
          );
        }
        if (
          this.serviceShare.compareObjects(
            this.serviceShare.YdocService.referenceCitationsMap.get('referenceCitations'),
            referenceCitations
          )
        ) {
          this.serviceShare.YdocService.referenceCitationsMap.set(
            'referenceCitations',
            referenceCitations
          );
        }
        btnsWrapper.style.display = 'none';
      });
    }
    if (!citationInfo || from == to) {
      editCitableElementsContainer.style.display = 'none';
      editCitationBtnContainer.style.display = 'none';
      deleteCitationBtnContainer.style.display = 'none';
    }
    if (this.citationButtonsPluginKey.getState(view.state).editorType == 'popupEditor') {
      btnsWrapper.style.display = 'none';
    }
  }

  findCitationMark(view: EditorView, pos: number) {
    const $pos = view.state.doc.resolve(pos);
    const { parent, parentOffset } = $pos;
    const { node } = parent.childAfter(parentOffset);
    const from = $pos.start() + parentOffset;
    return (
      node && {
        mark: node.marks?.find((mark) => this.citationElementsNodeNames.includes(mark.type?.name)),
        from,
        node,
      }
    );
  }

  setData(
    referenceCitations: any,
    referenceCitation: any,
    refCitationID: string,
    citedRefsIds: string[],
    citedRefsCiTOs: string[]
  ): void {
    let data: any;
    if (referenceCitations[refCitationID]) {
      data = {
        data: {
          text: referenceCitations[refCitationID].text,
          refCitationIDs: citedRefsIds,
          citationLayout: referenceCitations[refCitationID].citationLayout,
          sortOptions: referenceCitations[refCitationID].sortOptions,
          refCitationID,
          citedRefsCiTOs,
          isEditMode: true,
        },
        isEditMode: true,
      };
    } else {
      let isFind = false;
      Object.keys(referenceCitations).forEach((key: string) => {
        if (referenceCitations[key].text == referenceCitation.textContent && !isFind) {
          data = {
            data: {
              text: referenceCitations[key].text,
              refCitationIDs: citedRefsIds,
              citationLayout: referenceCitations[key].citationLayout,
              sortOptions: referenceCitations[key].sortOptions,
              refCitationID,
              citedRefsCiTOs,
              isEditMode: true,
            },
            isEditMode: true,
          };
          isFind = true;
        }
      });
      if (!isFind) {
        data = {
          data: {
            text: referenceCitation.textContent,
            refCitationIDs: citedRefsIds,
            citationLayout: {
              name: 'Default',
              layout: 'Default',
            },
            sortOptions: [
              {
                name: 'Default',
                tag: ['Default'],
              },
            ],
            refCitationID,
            citedRefsCiTOs,
            isEditMode: true,
          },
          isEditMode: true,
        };
      }
    }
    return data;
  }
}
