import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ArticleSection, basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { SectionContent } from '../../../../jats.models';

@Component({
  selector: 'app-section-in-list',
  templateUrl: './section-in-list.component.html',
  styleUrls: ['./section-in-list.component.scss'],
})
export class SectionInListComponent {
  @Input() parentListData!: {
    expandParentFunc: () => void;
    listDiv: HTMLDivElement;
    listInstance: SectionInListComponent;
  };
  @Input() parentId?: string; // the id of the parent of this node

  @Input() node!: SectionContent;
  @Output() nodeChange = new EventEmitter<ArticleSection>();

  @Input() nodeFormGroup!: UntypedFormGroup;
  @Output() nodeFormGroupChange = new EventEmitter<UntypedFormGroup>();

  @Input() lastNestedChild!: boolean;
  @Input() nestedNode!: boolean;

  @Input() isComplex!: boolean;

  @ViewChild('childrenDiv', { read: ElementRef }) childrenDiv?: ElementRef;

  focusedId?: string;
  mouseOn?: string;

  expandIcon = 'chevron_right';
  focusIdHold?: string;

  constructor(private ref: ChangeDetectorRef) {}

  get sectionBorderNestedInactive(): boolean {
    return !this.isComplex && this.mouseOn !== this.node.sectionID;
  }

  get sectionBorderListNestedInactive(): boolean {
    return this.mouseOn !== this.node.sectionID && this.isComplex;
  }

  get isFocused(): boolean {
    return this.node.sectionID == this.focusedId;
  }

  changeDisplay(div: HTMLDivElement): void {
    if (div.style.display == 'none') {
      div.style.display = 'block';
      this.expandIcon = 'expand_more';
    } else {
      div.style.display = 'none';
      this.expandIcon = 'chevron_right';
    }
    this.ref.detectChanges();
  }

  expandThisAndParentView = (): void => {
    this.expandParentFunc();
    if (
      this.childrenDiv &&
      this.childrenDiv.nativeElement &&
      this.childrenDiv.nativeElement.style.display == 'none'
    ) {
      this.changeDisplay(this.childrenDiv.nativeElement);
    }
  };

  expandParentFunc = (): void => {
    if (this.parentId !== 'parentList') {
      if (this.parentListData) {
        if (this.parentListData.listDiv.style.display == 'none') {
          this.parentListData.listDiv.style.display = 'block';
          this.parentListData.listInstance.expandIcon = 'expand_more';
          this.ref.detectChanges();
        }
        this.parentListData.expandParentFunc();
      }
    }
  };

  showButtons(
    div: HTMLDivElement,
    mouseOn: boolean,
    borderClass: string,
    focusClass: string,
    node: SectionContent
  ): void {
    if (mouseOn) {
      this.mouseOn = node.sectionID;
    } else {
      this.mouseOn = '';
    }
    Array.from(div.children).forEach((el: HTMLElement) => {
      if (el.classList.contains('hidden')) {
      } else if (el.classList.contains('border')) {
        if (mouseOn) {
          if (this.focusedId == node.sectionID) {
            this.focusIdHold = node.sectionID;
            this.focusedId = '';
          }
          el.className = `border ${borderClass} `;
        } else {
          if (this.focusIdHold == node.sectionID) {
            this.focusedId = this.focusIdHold;
            this.focusIdHold = '';
          }
          el.className = `border ${borderClass}Inactive`;
        }
      }
    });
  }
}
