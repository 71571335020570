export enum ArticleUserMode {
  readOnly = 'Read only',
  suggester = 'Can suggest',
  canCommentOnly = 'Can comment only',
  editor = 'Can edit',
}

export interface ArticleModeDetails {
  short: string;
  details: string;
  icon: string;
}

export type ArticleUserModeAbbreviationMapping = Record<ArticleUserMode, ArticleModeDetails>;
