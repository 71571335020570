import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CollaboratorsService } from '@app/editor/dialogs/add-contributors-dialog/collaborators.service';

import { ImportWizardDialogData } from '../../../jats.models';

@Component({
  selector: 'app-head-editor',
  templateUrl: './head-editor.component.html',
  styleUrls: ['./head-editor.component.scss'],
})
export class HeadEditorComponent implements AfterViewInit {
  @Input() data: ImportWizardDialogData;

  @ViewChild('ProsemirrorEditor', { read: ElementRef }) prosemirrorEditor?: ElementRef;

  constructor(private collaboratorsService: CollaboratorsService) {}

  ngAfterViewInit(): void {
    try {
      this.collaboratorsService.renderJatsHeadEditor(
        this.prosemirrorEditor?.nativeElement,
        this.data.allCollaborators,
        this.data.allAuthors
      );
    } catch (e) {
      console.error(e);
    }
  }
}
