import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import {
  AllUsersService,
  authorListData,
  contributorData,
  roleMapping,
  mapping,
} from '@app/core/services/all-users.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { EditContributorComponent } from './edit-contributor/edit-contributor.component';
import { SendInvitationComponent } from './send-invitation/send-invitation.component';
import { AuthService } from '@app/core/services/auth.service';
import {
  ArticleCollaborator,
  CollaboratorSearchResults,
  User,
} from '@app/core/models/article.models';
import { Role } from '@app/core/models/user.model';

@Component({
  selector: 'app-add-contributors-dialog',
  templateUrl: './add-contributors-dialog.component.html',
  styleUrls: ['./add-contributors-dialog.component.scss'],
})
export class AddContributorsDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('searchUsersInput', { read: ElementRef }) searchUsersInput?: ElementRef;

  collaboratorsSubs: Subscription;

  collaborators?: ArticleCollaborator[];
  isOwner = false;

  authors: ArticleCollaborator[];
  contributors: ArticleCollaborator[];

  ownerSettingsForm: UntypedFormGroup;
  searchFormControl = new UntypedFormControl('');

  dataIsLoaded = true;
  showError = false;
  invitingNewUser = false;

  searchData: contributorData[];

  authorsList: authorListData[];

  searchResults: CollaboratorSearchResults[] = [];

  currentUser?: User;

  emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  loading = false;

  mapping = mapping;

  colorFunction = this.sharedService.YdocService.setUserColor;

  constructor(
    public allUsersService: AllUsersService,
    private dialogRef: MatDialogRef<AddContributorsDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public sharedService: ServiceShare,
    private authService: AuthService,
    private ref: ChangeDetectorRef
  ) {
    this.ownerSettingsForm = formBuilder.group({
      accessAdding: false,
      disableOptionsPrint: false,
    });
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((value: string) => {
          if (value?.length) {
            this.loading = true;
          } else {
            this.loading = false;
            // this.searchData = [];
            // this.searchResults = [];
          }
          // this.loading = false;
          this.searchData = [];
          this.searchResults = [];
          return this.allUsersService.getAllUsersV2({
            page: 1,
            pageSize: 10,
            /* eslint-disable @typescript-eslint/naming-convention */
            'filter[search]': value,
          });
        })
      )
      .subscribe({
        next: (val: {
          meta: { filter: { search: string } };
          data: CollaboratorSearchResults[];
        }) => {
          this.loading = false;
          if (val.meta.filter && val.meta.filter.search && val.meta.filter.search !== 'null') {
            const results = val.data.filter(
              (user) => !this.collaborators.find((col) => col.email == user.email)
            );
            if (results.length > 0) {
              if (
                this.emailRegex.test(val.meta.filter.search) &&
                !results.find((u) => u.email == val.meta.filter.search) &&
                !this.collaborators.find((col) => col.email == val.meta.filter.search)
              ) {
                results.unshift({
                  email: val.meta.filter.search,
                  id: null,
                  name: val.meta.filter.search,
                });
              }
              this.searchData = results;
              this.searchResults = results;
              this.invitingNewUser = false;
            } else {
              if (this.emailRegex.test(val.meta.filter.search)) {
                const result = {
                  email: val.meta.filter.search,
                  id: null,
                  name: val.meta.filter.search,
                };
                this.searchData = [result];
                this.searchResults = [result];
                this.invitingNewUser = true;
              } else {
                this.searchData = [];
                this.searchResults = [];
              }
            }
          } else {
            this.searchData = [];
            this.searchResults = [];
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
    (document.getElementsByClassName('spinner-container')[0] as HTMLElement).style.zIndex = '1111';
  }

  hideResults(): void {
    this.searchResults = [];
  }

  showResult(): void {
    let val = this.searchFormControl.value;
    if (!val || val.length == 0) return;
    this.searchResults = this.searchData;
  }

  editContributor(contributorData: ArticleCollaborator): void {
    const dialogRef = this.dialog.open(EditContributorComponent, {
      maxWidth: '80%',
      panelClass: 'contributors-dialog',
      data: {
        contrData: {
          ...contributorData,
          affiliations: contributorData.affiliations.map((a) => ({
            affiliation: a.affiliation,
            city: a.city,
            country: a.country,
          })),
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.edited) {
        let editedContributors = [...this.collaborators];
        let authorsListCopy: authorListData[] = [...this.authorsList];
        let userIndex;
        if (contributorData.id) {
          userIndex = editedContributors.findIndex((user) => user.id == contributorData.id);
        } else {
          userIndex = editedContributors.findIndex((user) => user.email == contributorData.email);
        }
        let userInCollaboratorsArr = editedContributors[userIndex];
        if (result.removed && userInCollaboratorsArr) {
          this.sharedService.ProsemirrorEditorsService.spinSpinner();
          editedContributors.splice(userIndex, 1);
          userInCollaboratorsArr.type = mapping[userInCollaboratorsArr.access];

          let body = {
            article: {
              id: this.sharedService.YdocService.articleData.uuid,
              title: this.sharedService.YdocService.articleData.name,
            },
            invited: [userInCollaboratorsArr],
          };

          this.allUsersService.removeCollaborator(body).subscribe({
            next: () => {
              this.sharedService.ProsemirrorEditorsService.stopSpinner();
              if (
                userInCollaboratorsArr.role == Role.author ||
                userInCollaboratorsArr.role == Role.commenter
              ) {
                if (
                  userInCollaboratorsArr.id &&
                  authorsListCopy.some((x) => x.authorId == userInCollaboratorsArr.id)
                ) {
                  authorsListCopy = authorsListCopy.filter(
                    (x) => x.authorId != userInCollaboratorsArr.id
                  );
                } else if (
                  !userInCollaboratorsArr.id &&
                  userInCollaboratorsArr.email &&
                  authorsListCopy.some((x) => x.authorEmail == userInCollaboratorsArr.email)
                ) {
                  authorsListCopy = authorsListCopy.filter(
                    (x) => x.authorEmail != userInCollaboratorsArr.email
                  );
                }
              }
              if (authorsListCopy.length != this.authorsList.length) {
                this.sharedService.YdocService.collaborators.set('authorsList', authorsListCopy);
              }
              this.sharedService.YdocService.collaborators.set('collaborators', {
                collaborators: editedContributors,
              });
            },
            error: (err) => {
              console.error(err);
              this.sharedService.ProsemirrorEditorsService.stopSpinner();
            },
          });
        } /*if (result.access)*/ else {
          if (
            (userInCollaboratorsArr.role == Role.author ||
              userInCollaboratorsArr.role == Role.commenter) &&
            result.role == Role.contributor
          ) {
            let prop;
            let val;
            if (userInCollaboratorsArr.id) {
              prop = 'authorId';
              val = userInCollaboratorsArr.id;
            } else {
              prop = 'authorEmail';
              val = userInCollaboratorsArr.email;
            }
            let indexOfAuthor = authorsListCopy.findIndex((user) => user[prop] == val);
            if (indexOfAuthor >= 0) {
              authorsListCopy.splice(indexOfAuthor, 1);
            }
          } else if (
            userInCollaboratorsArr.role == Role.contributor &&
            (result.role == Role.author || result.role == Role.commenter)
          ) {
            authorsListCopy.push({
              authorEmail: userInCollaboratorsArr.email,
              authorId: userInCollaboratorsArr.id,
            });
          }
          editedContributors[userIndex].access = editedContributors[userIndex].is_owner
            ? 'Owner'
            : roleMapping[result.role];
          editedContributors[userIndex].role = result.role;
          editedContributors[userIndex].role_label = roleMapping[result.role];
          editedContributors[userIndex].affiliations = result.affiliations;
          editedContributors[userIndex].email = result.email;
          editedContributors[userIndex].first_name = result.firstName;
          editedContributors[userIndex].last_name = result.lastName;
          editedContributors[userIndex].name = `${result.firstName} ${result.lastName}`;
          editedContributors[userIndex].is_co_author = result.isCoAuthor;

          this.sharedService.ProsemirrorEditorsService.spinSpinner();
          const edited = editedContributors[userIndex];

          let body = {
            article: {
              id: this.sharedService.YdocService.articleData.uuid,
              title: this.sharedService.YdocService.articleData.name,
            },
            invited: [edited],
          };

          this.allUsersService.editCollaborator(body).subscribe({
            next: () => {
              if (
                this.sharedService.compareObjects(
                  this.sharedService.YdocService.getCollaborators(),
                  editedContributors
                )
              ) {
                this.sharedService.YdocService.collaborators.set('collaborators', {
                  collaborators: editedContributors,
                });
              }

              if (
                this.sharedService.compareObjects(
                  this.sharedService.YdocService.collaborators.get('authorsList'),
                  authorsListCopy
                )
              ) {
                this.sharedService.YdocService.collaborators.set('authorsList', authorsListCopy);
              }

              this.sharedService.ProsemirrorEditorsService.stopSpinner();
            },
            error: (err) => {
              console.error(err);
              this.sharedService.ProsemirrorEditorsService.stopSpinner();
            },
          });
          // } else {
          //   this.sharedService.YdocService.collaborators.set('collaborators', { collaborators: editedContributors });
          //   this.sharedService.YdocService.collaborators.set('authorsList', authorsListCopy)
          // }
        }
      }
    });
  }

  drop(event: CdkDragDrop<unknown[]>): void {
    let authorsCopy = [...this.authorsList];
    const movedAuthor = authorsCopy[event.previousIndex];

    moveItemInArray(authorsCopy, event.previousIndex, event.currentIndex);
    moveItemInArray(this.authors, event.previousIndex, event.currentIndex);

    this.sharedService.ProsemirrorEditorsService.spinSpinner();
    this.allUsersService
      .moveCollaborator({
        article_id: this.sharedService.YdocService.articleData.uuid,
        user_id: movedAuthor.authorId,
        position: event.currentIndex + 1,
      })
      .subscribe({
        next: () => {
          this.sharedService.ProsemirrorEditorsService.stopSpinner();
          this.sharedService.YdocService.collaborators.set('authorsList', authorsCopy);
        },
        error: (err) => {
          this.sharedService.ProsemirrorEditorsService.stopSpinner();
          console.error(err);
        },
      });
  }

  filterSearchResults(filterVal: string): CollaboratorSearchResults[] {
    if (this.collaborators && this.currentUser && this.searchData) {
      return this.searchData.filter((user) => {
        return (
          user.email.includes(filterVal.toLocaleLowerCase()) &&
          user.email != this.currentUser.email &&
          !this.collaborators.find((col) => col.email == user.email)
        );
      });
    } else {
      return [];
    }
  }

  setCollaboratorsData(collaboratorsData: { collaborators: ArticleCollaborator[] }): void {
    setTimeout(() => {
      this.collaborators = collaboratorsData.collaborators;
      this.authorsList = this.sharedService.YdocService.collaborators.get('authorsList');

      this.authors = this.authorsList.map((user) => {
        let prop;
        let val;
        if (user.authorId) {
          prop = 'id';
          val = user.authorId;
        } else {
          prop = 'email';
          val = user.authorEmail;
        }
        return this.collaborators.find((x) => x[prop] == val);
      });

      this.contributors = this.collaborators.filter((user) => {
        let prop;
        let val;
        if (user.id) {
          prop = 'authorId';
          val = user.id;
        } else {
          prop = 'authorEmail';
          val = user.email;
        }
        return !this.authorsList.some((x) => x[prop] == val);
      });

      if (this.currentUser) {
        this.checkIfCurrentUserIsOwner();
      }
    }, 30);
  }

  checkIfCurrentUserIsOwner(): void {
    let user = this.collaborators.find((col) => {
      return col.id == this.currentUser.id;
    });

    this.sharedService.EnforcerService.enforceAsync('is-admin', 'admin-can-do-anything').subscribe(
      (admin) => {
        if (admin) {
          this.isOwner = true;
        } else {
          if (user?.access == 'Owner') {
            this.isOwner = true;
          }
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.authService.currentUser$.subscribe((response) => {
      this.currentUser = response;
      if (this.collaborators) {
        this.checkIfCurrentUserIsOwner();
      }
    });
    this.collaboratorsSubs = this.sharedService.YdocService.collaboratorsSubject.subscribe(
      (data) => {
        this.setCollaboratorsData(data);
      }
    );
    this.setCollaboratorsData(this.sharedService.YdocService.collaborators.get('collaborators'));
    this.searchUsersInput.nativeElement.focus();
    this.ref.detectChanges();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  openAddContributorDialog(contributor: { email: string; id: string; name: string }): void {
    const dialogRef = this.dialog.open(SendInvitationComponent, {
      maxWidth: '80%',
      panelClass: 'contributors-dialog',
      data: { contributor },
    });

    dialogRef.afterClosed().subscribe(
      (result: {
        usersChipList: ArticleCollaborator[];
        roleSelect: string;
        affiliations: {
          affiliation: string;
          city: string;
          country: string;
        }[];
        message: string;
        isCoAuthor: boolean;
      }) => {
        if (result && result.usersChipList.length > 0 && this.collaborators) {
          this.sharedService.ProsemirrorEditorsService.spinSpinner();
          let collaboratorsCopy = [...this.collaborators];
          result.usersChipList.forEach((newCollaborator) => {
            if (newCollaborator.id == null) {
              const id = newCollaborator.email;
              collaboratorsCopy.push({
                ...newCollaborator,
                role: result.roleSelect,
                role_label: roleMapping[result.roleSelect],
                is_co_author: result.isCoAuthor,
                affiliations: result.affiliations,
                id,
              });
            } else {
              const collaborator = {
                name: newCollaborator.name,
                first_name: newCollaborator.first_name,
                last_name: newCollaborator.last_name,
                email: newCollaborator.email,
                id: newCollaborator.id,
                role: result.roleSelect,
                role_label: roleMapping[result.roleSelect],
                hide_me_from_user: [],
                hide_my_comments_from_user: [],
                allowed_article_versions: [],
                is_co_author: result.isCoAuthor,
                access: roleMapping[result.roleSelect],
                affiliations: result.affiliations,
              } as unknown as ArticleCollaborator;

              collaboratorsCopy.push(collaborator);
            }
          });
          let authorsListCopy: authorListData[] = [...this.authorsList];
          if (result.roleSelect == Role.author || result.roleSelect == Role.commenter) {
            authorsListCopy.push(
              ...result.usersChipList.map((user: ArticleCollaborator) => {
                if (user.id == null) {
                  const authorId = user.email;
                  return { authorId, authorEmail: user.email };
                } else {
                  return { authorId: user.id, authorEmail: user.email };
                }
              })
            );
          }
          let articleData = {
            id: this.sharedService.YdocService.articleData.uuid,
            title: this.sharedService.YdocService.articleData.name,
          };
          let postBody = {
            article: articleData,
            message: result.message,
            invited: result.usersChipList.map((x: ArticleCollaborator) => {
              const contributor = collaboratorsCopy.find((c) => c.id == x.id);

              if (contributor) {
                return {
                  id: contributor.id,
                  email: contributor.email,
                  first_name: contributor.first_name,
                  last_name: contributor.first_name,
                  name: contributor.name,
                  role: contributor.role.toLocaleLowerCase(),
                  is_co_author: result.isCoAuthor,
                  affiliations: contributor.affiliations,
                };
              } else {
                return {
                  ...x,
                  role: result.roleSelect.toLocaleLowerCase(),
                  affiliations: result.affiliations,
                };
              }
            }),
          };

          this.allUsersService.sendInviteInformation(postBody).subscribe(
            () => {
              this.sharedService.YdocService.collaborators.set('collaborators', {
                collaborators: collaboratorsCopy,
              });
              this.sharedService.YdocService.collaborators.set('authorsList', authorsListCopy);
              this.setCollaboratorsData(
                this.sharedService.YdocService.collaborators.get('collaborators')
              );
              this.sharedService.ProsemirrorEditorsService.stopSpinner();
            },
            (err) => {
              console.error(err);
              this.sharedService.ProsemirrorEditorsService.stopSpinner();
            }
          );
        }
        this.searchFormControl.setValue(null);
      }
    );
  }

  hasContributors(contributors: ArticleCollaborator[]): number {
    return (
      contributors.length &&
      contributors.filter((c) => !this.allUsersService.isHidden(c.auth_role)).length
    );
  }

  ngOnDestroy(): void {
    (document.getElementsByClassName('spinner-container')[0] as HTMLElement).style.zIndex = '999';
    if (this.collaboratorsSubs) {
      this.collaboratorsSubs.unsubscribe();
    }
  }
}
