import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Map } from 'yjs';

import { YdocService } from '../services/ydoc.service';
import { ArticleSection, basicArticleSection } from '../utils/interfaces/articleSection';
import { ProsemirrorEditorsService } from '../services/prosemirror-editors.service';
import { figure } from '../utils/interfaces/figureComponent';
import { DetectFocusService } from '../utils/detectFocusPlugin/detect-focus.service';
import { ServiceShare } from '../services/service-share.service';
import { User } from '@app/core/models/article.models';
import { CslService } from '@app/layout/pages/library/lib-service/csl.service';
import { VersionData } from '@app/core/models/version.models';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent implements OnInit {
  @Input() versionData: VersionData;

  @ViewChild('headEditor', { read: ElementRef }) headEditor?: ElementRef;

  moveHeadEditor = false;
  articleSectionsStructureFlat: string[];
  articleSectionsStructure: basicArticleSection[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  articleStructureMap: Map<any>;
  articleFigures?: figure[];
  createdEditorIdsFromOldVersions = [];
  doNotRenderEndEditor = this.ydocService.doNotRenderEndEditor;
  hasFootnotes = this.ydocService.hasFootnotes;
  hasSupplementaryMaterials = this.ydocService.hasSupplementaryMaterials;

  constructor(
    private ydocService: YdocService,
    public prosemirrorEditorsService: ProsemirrorEditorsService,
    public detectFocusService: DetectFocusService,
    public serviceShare: ServiceShare
  ) {
    this.articleStructureMap = this.ydocService.articleStructureMap;
    this.ydocService.articleStructureMap.observe(() => {
      this.articleSectionsStructure = this.ydocService.articleStructureMap.get(
        'articleSectionsStructure'
      );
      const change = this.serviceShare?.TreeService?.metadatachangeMap!.get('change');
      if (
        change &&
        (change.action == 'editNode' || change.action == 'saveNewTitle') &&
        this.serviceShare.TreeService!.findNodeById(change.nodeId || change.node.sectionID)?.active
      ) {
        return;
      }
      this.makeFlat();

      setTimeout(() => {
        if (this.detectFocusService.sectionName) {
          if (
            !this.prosemirrorEditorsService.editorContainers[this.detectFocusService.sectionName]
          ) {
            this.detectFocusService.sectionName = undefined;
          } else {
            const editorView =
              this.prosemirrorEditorsService.editorContainers[this.detectFocusService.sectionName]
                .editorView;
            editorView.focus();
            editorView.dispatch(editorView.state.tr.scrollIntoView());
          }
        }
      }, 50);
    });
    this.articleSectionsStructure = this.ydocService.articleStructureMap.get(
      'articleSectionsStructure'
    );
    this.makeFlat();
    this.articleFigures = this.ydocService.figuresMap?.get('ArticleFigures');
  }

  get hasCitableElements(): boolean {
    return (Object.keys(this.ydocService.referenceCitationsMap.get('refsAddedToArticle')).length >
      0 ||
      Object.values(this.ydocService.figuresMap.get('ArticleFigures')).find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (f: any) => f.figurePlace == 'endEditor'
      ) ||
      Object.values(this.ydocService.tablesMap.get('ArticleTables')).find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (t: any) => t.tablePlace == 'endEditor'
      )) as boolean;
  }

  get hasSupplementaryFiles(): boolean {
    return Object.keys(this.ydocService.supplementaryFilesMap.get('supplementaryFiles')).length > 0;
  }

  get hasFootNotes(): boolean {
    return Object.keys(this.ydocService.endNotesMap.get('endNotes')).length > 0;
  }

  ngOnInit(): void {
    this.serviceShare.makeFlat = this.makeFlat;
  }

  getSection = (id: string): ArticleSection => this.ydocService.getSectionByID(id);

  makeFlat = (): string[] => {
    const articleSectionsStructureFlat: string[] = [];

    const makeFlat = (structure: basicArticleSection[]): void => {
      if (structure) {
        structure.forEach((section) => {
          if (section.active) {
            articleSectionsStructureFlat.push(section.sectionID);
          }
          if (section.children.length > 0) {
            makeFlat(section.children);
          }
        });
      }
    };
    makeFlat(this.articleSectionsStructure);
    if (this.ydocService.shouldRestore) {
      this.ydocService.ydoc
        .getMap('articleStructure')
        .set('articleSectionsStructure', this.articleSectionsStructure);
      this.ydocService.ydoc
        .getMap('articleStructure')
        .set('articleSectionsStructureFlat', articleSectionsStructureFlat);
      this.ydocService.shouldRestore = false;
    }
    this.articleSectionsStructureFlat = [];
    setTimeout(() => {
      if (this.headEditor) {
        this.moveHeadEditor = articleSectionsStructureFlat.some((id) => {
          return (
            this.ydocService.getSectionByID(id).jats_tag &&
            this.ydocService.getSectionByID(id).jats_tag == 'article-title'
          );
        });
      }
      this.articleSectionsStructureFlat = articleSectionsStructureFlat;
    }, 10);
    return articleSectionsStructureFlat;
  };

  trackSectionById(index: number, sectionID: string): string {
    return sectionID;
  }
}
