import { PluginKey, TextSelection, EditorState, Transaction } from 'prosemirror-state';
import { EditorView, NodeView } from 'prosemirror-view';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { StepMap } from 'prosemirror-transform';
import { keymap } from 'prosemirror-keymap';
import { chainCommands, deleteSelection, newlineInCode } from 'prosemirror-commands';
import katex from 'katex';

interface MathViewOptions {
  katexOptions?: katex.KatexOptions;
  tagName?: string;
}

class MathView implements NodeView {
  private _node: ProseMirrorNode;
  private _outerView: EditorView;
  private _getPos: () => number;
  private _onDestroy?: () => void;
  private _mathPluginKey: PluginKey;
  private cursorSide: 'start' | 'end' = 'start';
  private _isEditing: boolean = false;
  private _katexOptions: katex.KatexOptions;
  private _tagName: string;
  private _mathRenderElt: HTMLElement;
  private _mathSrcElt: HTMLElement;
  private _innerView?: EditorView;
  dom: HTMLElement;

  constructor(
    node: ProseMirrorNode,
    view: EditorView,
    getPos: () => number,
    options: MathViewOptions = {},
    mathPluginKey: PluginKey,
    onDestroy?: () => void
  ) {
    this._node = node;
    this._outerView = view;
    this._getPos = getPos;
    this._onDestroy = onDestroy ? onDestroy.bind(this) : undefined;
    this._mathPluginKey = mathPluginKey;

    // Editing state
    this.cursorSide = 'start';
    this._isEditing = false;

    // Options
    this._katexOptions = Object.assign(
      { globalGroup: true, throwOnError: false },
      options.katexOptions
    );
    this._tagName = options.tagName || this._node.type.name.replace('_', '-');

    // Create DOM representation of node view
    this.dom = document.createElement(this._tagName);
    this.dom.classList.add('math-node');
    this.dom.setAttribute('style', node.attrs.style);

    this._mathRenderElt = document.createElement('span');
    this._mathRenderElt.textContent = '';
    this._mathRenderElt.classList.add('math-render');
    this.dom.appendChild(this._mathRenderElt);

    this._mathSrcElt = document.createElement('span');
    this._mathSrcElt.classList.add('math-src');
    this.dom.appendChild(this._mathSrcElt);

    // Add click event to ensure focus
    this.dom.addEventListener('click', () => this.ensureFocus());

    // Render initial content
    this.renderMath();
  }

  destroy() {
    // Close the inner editor without rendering
    this.closeEditor(false);

    // Clean up DOM elements
    if (this._mathRenderElt) {
      this._mathRenderElt.remove();
      // Use `delete` carefully - only for optional or undefined fields
      this._mathRenderElt = undefined as unknown as HTMLElement; // Cast to undefined after removing
    }

    if (this._mathSrcElt) {
      this._mathSrcElt.remove();
      this._mathSrcElt = undefined as unknown as HTMLElement; // Cast to undefined after removing
    }

    // Remove the root DOM element
    this.dom.remove();
  }

  ensureFocus() {
    if (this._innerView && this._outerView.hasFocus()) {
      this._innerView.focus();
    }
  }

  update(node: ProseMirrorNode, decorations: any): boolean {
    // Check if the new node has the same markup
    if (!node.sameMarkup(this._node)) {
      return false;
    }

    // Update the node reference
    this._node = node;

    // If the inner editor view exists, synchronize its state with the new node content
    if (this._innerView) {
      const state = this._innerView.state;
      const start = node.content.findDiffStart(state.doc.content);

      // If there's a difference between the new node and the inner editor's state
      if (start !== null) {
        const diff = node.content.findDiffEnd(state.doc.content);
        if (diff) {
          let { a: endA, b: endB } = diff;
          const overlap = start - Math.min(endA, endB);
          if (overlap > 0) {
            endA += overlap;
            endB += overlap;
          }

          // Dispatch a transaction to replace the differing content in the inner editor
          this._innerView.dispatch(
            state.tr.replace(start, endB, node.slice(start, endA)).setMeta('fromOutside', true)
          );
        }
      }
    }
  }

  renderMath(): void {
    if (!this._mathRenderElt) {
      return;
    }

    // Get TeX string to render
    //@ts-ignore
    const content = this._node.content.content;
    let texString = '';

    if (content.length > 0 && content[0].textContent !== null) {
      texString = content[0].textContent.trim();
    }

    if (texString.length < 1) {
      return;
    }

    // Handle empty math
    if (texString.length < 1) {
      this.dom.classList.add('empty-math');
      // Clear the rendered math since the node is in an invalid state
      while (this._mathRenderElt.firstChild) {
        this._mathRenderElt.firstChild.remove();
      }
      return; // Do not render empty math
    } else {
      this.dom.classList.remove('empty-math');
    }

    // Render with KaTeX, fail gracefully
    try {
      katex.render(texString, this._mathRenderElt, this._katexOptions);
      this._mathRenderElt.classList.remove('parse-error');
      this.dom.setAttribute('title', '');
    } catch (err) {
      if (err instanceof katex.ParseError) {
        console.error(err);
        this._mathRenderElt.classList.add('parse-error');
        this.dom.setAttribute('title', err.toString());
      } else {
        throw err; // Re-throw non-KaTeX errors
      }
    }
  }

  updateCursorPos(state: EditorState) {
    const pos = this._getPos();
    const size = this._node.nodeSize;
    const inPmSelection = state.selection.from < pos + size && pos < state.selection.to;

    // Update cursorSide based on whether the cursor is before or after the node
    if (!inPmSelection) {
      this.cursorSide = pos < state.selection.from ? 'end' : 'start';
    }
  }

  // Event for selecting a node, triggers opening the editor
  selectNode() {
    if (!this._outerView.editable) {
      return;
    }
    this.dom.classList.add('ProseMirror-selectednode');
    if (!this._isEditing) {
      this.openEditor();
    }
  }

  // Event for deselecting a node, closes the editor if it is open
  deselectNode() {
    this.dom.classList.remove('ProseMirror-selectednode');
    if (this._isEditing) {
      this.closeEditor();
    }
  }

  // Determines whether to stop the propagation of a given event
  stopEvent(event: Event): boolean {
    return !!(
      this._innerView &&
      event.target &&
      this._innerView.dom.contains(event.target as Node)
    );
  }

  // Prevents mutations from being applied to the node's DOM
  ignoreMutation(): boolean {
    return true;
  }

  openEditor() {
    if (this._innerView) {
      throw new Error('Inner view should not exist!');
    }

    // Create a nested ProseMirror view for the math node
    this._innerView = new EditorView(this._mathSrcElt, {
      state: EditorState.create({
        doc: this._node,
        plugins: [
          keymap({
            Tab: (state, dispatch) => {
              if (dispatch) {
                dispatch(state.tr.insertText('\t'));
              }
              return true;
            },
            Backspace: chainCommands(deleteSelection, (state, dispatch) => {
              // Handle non-empty selections
              if (!state.selection.empty) {
                return false;
              }
              // Handle non-empty math node
              if (this._node.textContent.length > 0) {
                return false;
              }
              // Delete empty math node and focus outer view
              this._outerView.dispatch(this._outerView.state.tr.insertText(''));
              this._outerView.focus();
              return true;
            }),
            'Ctrl-Backspace': (state, dispatch) => {
              // Delete math node and focus outer view
              this._outerView.dispatch(this._outerView.state.tr.insertText(''));
              this._outerView.focus();
              return true;
            },
            Enter: chainCommands(newlineInCode, collapseMathCmd(this._outerView, +1, false)),
            'Ctrl-Enter': collapseMathCmd(this._outerView, +1, false),
            ArrowLeft: collapseMathCmd(this._outerView, -1, true),
            ArrowRight: collapseMathCmd(this._outerView, +1, true),
            ArrowUp: collapseMathCmd(this._outerView, -1, true),
            ArrowDown: collapseMathCmd(this._outerView, +1, true),
          }),
        ],
      }),
      dispatchTransaction: this.dispatchInner.bind(this),
    });

    // Focus the inner editor
    const innerState = this._innerView.state;
    this._innerView.focus();

    // Get the previous cursor position from the plugin key state
    const maybePos = this._mathPluginKey.getState(this._outerView.state)?.prevCursorPos ?? 0;
    if (maybePos === 0) {
      console.error('[prosemirror-math] Error: Unable to fetch math plugin state from key.');
    }

    // Compute the position for the cursor in the expanded math node
    const innerPos = maybePos <= this._getPos() ? 0 : this._node.nodeSize - 2;

    // Set the selection in the inner editor
    this._innerView.dispatch(
      innerState.tr.setSelection(TextSelection.create(innerState.doc, innerPos))
    );

    this._isEditing = true;
  }

  closeEditor(render = true) {
    if (this._innerView) {
      this._innerView.destroy();
      this._innerView = undefined;
    }
    if (render) {
      this.renderMath();
    }
    this._isEditing = false;
  }

  dispatchInner(tr: Transaction) {
    if (!this._innerView) {
      return;
    }

    // Apply the transaction to the inner view's state and get the transactions
    const { state, transactions } = this._innerView.state.applyTransaction(tr);
    this._innerView.updateState(state);

    // Check if the transaction came from the outer view
    if (!tr.getMeta('fromOutside')) {
      const outerTr = this._outerView.state.tr;
      const offsetMap = StepMap.offset(this._getPos() + 1);

      // Map each step from the inner transactions to the outer transaction
      transactions.forEach(({ steps }) => {
        steps.forEach((step) => {
          const mapped = step.map(offsetMap);
          if (!mapped) {
            throw new Error('Step discarded!');
          }
          outerTr.step(mapped);
        });
      });

      // Dispatch the outer transaction if it has changed the document
      if (outerTr.docChanged) {
        this._outerView.dispatch(outerTr);
      }
    }
  }
}

function collapseMathCmd(
  outerView: EditorView,
  dir: number,
  requireOnBorder: boolean,
  requireEmptySelection: boolean = true
): (innerState: EditorState, dispatch: ((tr: Transaction) => void) | undefined) => boolean {
  return (innerState, dispatch) => {
    const outerState = outerView.state;
    const { to: outerTo, from: outerFrom } = outerState.selection;
    const { to: innerTo, from: innerFrom } = innerState.selection;

    if (requireEmptySelection && innerTo !== innerFrom) return false;

    const currentPos = dir > 0 ? innerTo : innerFrom;
    if (requireOnBorder) {
      const nodeSize = innerState.doc.nodeSize - 2;
      if ((dir > 0 && currentPos < nodeSize) || (dir < 0 && currentPos > 0)) {
        return false;
      }
    }

    if (dispatch) {
      const targetPos = dir > 0 ? outerTo : outerFrom;
      outerView.dispatch(
        outerState.tr.setSelection(TextSelection.create(outerState.doc, targetPos))
      );
      outerView.focus();
    }
    return true;
  };
}

export { MathView };
