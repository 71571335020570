import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { ImportJatsService, JatsModule } from '../importAsJatsXML.service';
import { updateYFragment } from '../../../../../y-prosemirror-src/plugins/sync-plugin.js';
import { ImportWizardDialogData, SectionContent } from '../jats.models';
import { ConfirmationDialogComponent } from '@app/editor/dialogs/confirmation-dialog/confirmation-dialog.component';
import { getSectionBasicStructure } from '@app/editor/utils/articleBasicStructure';

@Component({
  selector: 'app-import-wizard-dialog',
  templateUrl: './import-wizard-modal.component.html',
  styleUrls: ['./import-wizard-modal.component.scss'],
})
export class ImportWizardDialogComponent {
  sections: SectionContent[];
  articleSections: basicArticleSection[] = this.sharedService.YdocService.articleStructureMap.get(
    'articleSectionsStructure'
  );

  shouldPreserve = false;
  isLoading = false;

  constructor(
    public dialog: MatDialog,
    public sharedService: ServiceShare,
    public importJatsService: ImportJatsService,
    @Inject(MAT_DIALOG_DATA)
    public data: { from: JatsModule.article | JatsModule.dashboard; data: ImportWizardDialogData },
    private dialogRef: MatDialogRef<ImportWizardDialogComponent>
  ) {
    this.sections = this.data.data.sectionsContent;
  }

  preserveIntervalOption(): void {
    this.isLoading = true;
    this.shouldPreserve = !this.shouldPreserve;

    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  submitImport(): void {
    if (this.data.from == JatsModule.article) {
      this.importJatsService.addCitableElementsToYdoc();
      this.importJatsService.inviteContributors();

      const editors = this.sharedService.ProsemirrorEditorsService.editorContainers;
      const xmlFragments = this.sharedService.ProsemirrorEditorsService.xmlFragments;

      this.sharedService.ProsemirrorEditorsService.spinSpinner();

      const newSectionStructure: basicArticleSection[] = [];
      const newSectionStructureFlat: string[] = [];

      const findExistingSection = (
        sections: basicArticleSection[],
        sectionContent: SectionContent
      ) => {
        let foundedSection: basicArticleSection;

        sections.forEach((s) => {
          if (!foundedSection) {
            if (s.sectionID == sectionContent.originalSecID) {
              foundedSection = s;
            }

            if (s.children?.length > 0) {
              findExistingSection(s.children, sectionContent);
            }
          }
        });

        return foundedSection;
      };

      const createNewSectionStructure = (
        sectionsContent: SectionContent[],
        parent?: basicArticleSection
      ) => {
        sectionsContent.forEach((s) => {
          let foundedSection = findExistingSection(this.articleSections, s);

          if (foundedSection) {
            foundedSection = JSON.parse(JSON.stringify(foundedSection));

            const articleSection = this.sharedService.YdocService.getSectionByID(
              foundedSection.sectionID
            );

            const editorContainer = editors[foundedSection.sectionID];
            editorContainer.editorID = s.sectionID;
            editors[s.sectionID] = editorContainer;

            const xmlFragment = xmlFragments[foundedSection.sectionID];
            xmlFragments[s.sectionID] = xmlFragment;
            newSectionStructureFlat.push(s.sectionID);

            const basicSection = getSectionBasicStructure(this.sharedService.YdocService)(
              articleSection
            );

            if (s.subsections) {
              createNewSectionStructure(s.subsections, basicSection);
            }

            if (!parent) {
              newSectionStructure.push(basicSection);
            } else {
              parent.children.push(basicSection);
            }
          } else if (this.importJatsService.generalSection) {
            const div = document.createElement('div');
            const generalSection = JSON.parse(
              JSON.stringify(this.importJatsService.generalSection)
            );

            generalSection.sectionID = s.sectionID;
            generalSection.title.label = s.parsedSecTitle;

            this.sharedService.ProsemirrorEditorsService.renderBasicEditorFromImport(
              div,
              s.sectionID,
              generalSection
            );

            newSectionStructureFlat.push(s.sectionID);
            this.sharedService.YdocService.articleSectionsMap.set(s.sectionID, generalSection);

            const basicSection = getSectionBasicStructure(this.sharedService.YdocService)(
              generalSection
            );

            if (s.subsections) {
              createNewSectionStructure(s.subsections, basicSection);
            }

            if (!parent) {
              newSectionStructure.push(basicSection);
            } else {
              parent.children.push(basicSection);
            }
          }
        });
      };

      createNewSectionStructure(this.sections);

      this.sharedService.YdocService.articleStructureMap.set(
        'articleSectionsStructure',
        newSectionStructure
      );
      this.sharedService.YdocService.articleStructureMap.set(
        'articleSectionsStructureFlat',
        newSectionStructureFlat
      );

      const parseAllSections = (sectionsContent: SectionContent[]) => {
        sectionsContent.forEach((section) => {
          const { doc } = section;

          if (
            section.originalSectionName == '[AM] Title' &&
            this.sharedService.titleControl.value == 'Untitled' &&
            doc.textContent?.trim()
          ) {
            this.sharedService.titleControl.setValue(
              doc.textContent.replace(this.sharedService.escapeHtmlTags, '')
            );
          }

          if (doc) {
            const editor = editors[section.sectionID];

            if (editor) {
              const xmlFragment = xmlFragments[section.sectionID];
              updateYFragment(this.sharedService.YdocService.ydoc, xmlFragment, doc, new Map());
            }
          }

          if (section.subsections?.length) {
            parseAllSections(section.subsections);
          }
        });
      };

      parseAllSections(this.sections);

      this.dialogRef.close(true);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '532px',
        data: {
          title: 'Warning',
          content: 'All existing content in the document will be deleted.',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.confirmed) {
          this.importJatsService.addCitableElementsToEditor();
          this.importJatsService.inviteContributors();

          const editors = this.sharedService.ProsemirrorEditorsService.editorContainers;
          const xmlFragments = this.sharedService.ProsemirrorEditorsService.xmlFragments;

          this.sharedService.ProsemirrorEditorsService.spinSpinner();

          setTimeout(() => {
            const newSectionStructure: basicArticleSection[] = [];
            const newSectionStructureFlat: string[] = [];

            const findExistingSection = (
              sections: basicArticleSection[],
              sectionContent: SectionContent
            ) => {
              let foundedSection: basicArticleSection;

              sections.forEach((s) => {
                if (!foundedSection) {
                  if (s.sectionID == sectionContent.originalSecID) {
                    foundedSection = s;
                  }

                  if (s.children?.length > 0) {
                    findExistingSection(s.children, sectionContent);
                  }
                }
              });

              return foundedSection;
            };

            const createNewSectionStructure = (
              sectionsContent: SectionContent[],
              parent?: basicArticleSection
            ) => {
              sectionsContent.forEach((s) => {
                debugger;
                let foundedSection = findExistingSection(this.articleSections, s);

                if (foundedSection) {
                  foundedSection = JSON.parse(JSON.stringify(foundedSection));

                  const articleSection = this.sharedService.YdocService.getSectionByID(
                    foundedSection.sectionID
                  );

                  const editorContainer = editors[foundedSection.sectionID];
                  editorContainer.editorID = s.sectionID;
                  editors[s.sectionID] = editorContainer;

                  const xmlFragment = xmlFragments[foundedSection.sectionID];
                  xmlFragments[s.sectionID] = xmlFragment;
                  newSectionStructureFlat.push(s.sectionID);

                  const basicSection = getSectionBasicStructure(this.sharedService.YdocService)(
                    articleSection
                  );

                  if (s.subsections) {
                    createNewSectionStructure(s.subsections, basicSection);
                  }

                  if (!parent) {
                    newSectionStructure.push(basicSection);
                  } else {
                    parent.children.push(basicSection);
                  }
                } else if (this.importJatsService.generalSection) {
                  const div = document.createElement('div');
                  const generalSection = JSON.parse(
                    JSON.stringify(this.importJatsService.generalSection)
                  );

                  generalSection.sectionID = s.sectionID;
                  generalSection.title.label = s.parsedSecTitle;

                  this.sharedService.ProsemirrorEditorsService.renderBasicEditorFromImport(
                    div,
                    s.sectionID,
                    generalSection
                  );

                  newSectionStructureFlat.push(s.sectionID);
                  this.sharedService.YdocService.articleSectionsMap.set(
                    s.sectionID,
                    generalSection
                  );

                  const basicSection = getSectionBasicStructure(this.sharedService.YdocService)(
                    generalSection
                  );

                  if (s.subsections) {
                    createNewSectionStructure(s.subsections, basicSection);
                  }

                  if (!parent) {
                    newSectionStructure.push(basicSection);
                  } else {
                    parent.children.push(basicSection);
                  }
                }
              });
            };

            createNewSectionStructure(this.sections);

            this.sharedService.YdocService.articleStructureMap.set(
              'articleSectionsStructure',
              newSectionStructure
            );
            this.sharedService.YdocService.articleStructureMap.set(
              'articleSectionsStructureFlat',
              newSectionStructureFlat
            );

            const parseAllSections = (sectionsContent: SectionContent[]) => {
              sectionsContent.forEach((section) => {
                const { doc } = section;

                if (
                  section.originalSectionName == '[AM] Title' &&
                  this.sharedService.titleControl.value == 'Untitled' &&
                  doc.textContent?.trim()
                ) {
                  this.sharedService.titleControl.setValue(
                    doc.textContent.replace(this.sharedService.escapeHtmlTags, '')
                  );
                }

                if (section.originalSectionName.includes('[AM]')) {
                  // debugger;
                }

                if (doc) {
                  const editor = editors[section.sectionID];

                  if (editor) {
                    const xmlFragment = xmlFragments[section.sectionID];
                    updateYFragment(
                      this.sharedService.YdocService.ydoc,
                      xmlFragment,
                      doc,
                      new Map()
                    );

                    // if (
                    //   section.formIOSchema?.optional &&
                    //   'content' in doc.content &&
                    //   Array.isArray(doc.content?.content) &&
                    //   doc.content?.content?.length > 1
                    // ) {
                    //   this.sharedService.TreeService.showHideSection(data.sectionID, 'block');
                    // }
                  }
                }

                if (section.subsections?.length) {
                  parseAllSections(section.subsections);
                }
              });
            };

            this.sharedService.YdocService.importSubject.next('import');

            parseAllSections(this.sections);

            setTimeout(() => {
              this.sharedService.YdocService.importSubject.next('render');
              setTimeout(() => {
                this.sharedService.YdocService.ydocStateObservable.subscribe(({ event }) => {
                  if (event == 'docIsBuild') {
                    setTimeout(() => {
                      this.sharedService.updateCitableElementsViewsAndCites();
                      this.sharedService.ProsemirrorEditorsService.stopSpinner();

                      // this.sharedService.TaxonService.markTaxonsWithBackendService() - disabled for now...
                    }, 1000);
                  }
                });
              }, 1000);
            }, 1000);
          }, 1500);
          this.dialogRef.close(true);
        }
      });
    }
  }

  cancelImport(): void {
    this.dialogRef.close(undefined);
    this.importJatsService.setEmptyCitableElements();
    this.sharedService.ProsemirrorEditorsService.stopSpinner();
  }
}
