/* eslint-disable @typescript-eslint/naming-convention */
export interface DocumentState {
  state_name: string;
  doi: string;
}

export enum EditingRights {
  readOnly = 'Read only',
  commentOnly = 'Can comment only',
  edit = 'Can edit',
}

export interface ArticleState {
  id: number;
  name: string;
}

export interface ArticleStateExtended extends ArticleState {
  short: string;
  abbreviation: string;
}

export const stateNames = [
  { id: 1, name: 'New article', short: 'New Article', abbreviation: 'NEWA' },
  { id: 2, name: 'Pre-review evaluation', short: 'Pre-review', abbreviation: 'PRER' },
  { id: 3, name: 'In review', short: 'Review', abbreviation: 'REVI' },
  { id: 4, name: 'In production', short: 'Production', abbreviation: 'PROD' },
  { id: 5, name: 'Published', short: 'Published', abbreviation: 'PUBD' },
  { id: 6, name: 'Archived', short: 'Archived', abbreviation: 'ARCH' },
  { id: 7, name: 'Rejected', short: 'Rejected', abbreviation: 'REJ' },
  { id: 8, name: 'In copy editing', short: 'Copy Editing', abbreviation: 'COPY' },
  {
    id: 9,
    name: 'Waiting author version after review round',
    short: 'Awaiting Version',
    abbreviation: 'WAVR',
  },
  { id: 10, name: 'PDF proof submitted', short: 'PDF Submitted', abbreviation: 'PDFS' },
  { id: 11, name: 'Approved for publication', short: 'Approved', abbreviation: 'APPR' },
  {
    id: 12,
    name: 'Waiting author to proceed to layout editing',
    short: 'Awaiting Layout',
    abbreviation: 'LAYT',
  },
  { id: 13, name: 'Ready for layout', short: 'Layout Ready', abbreviation: 'RLAY' },
  {
    id: 14,
    name: 'Waiting author to proceed to copy editing',
    short: 'Awaiting Copy Edit',
    abbreviation: 'CPED',
  },
  { id: 15, name: 'Ready for copy edit', short: 'Copy Edit Ready', abbreviation: 'RCPE' },
  {
    id: 16,
    name: 'Rejected, but resubmission encouraged',
    short: 'Rej. Resubmit',
    abbreviation: 'REJR',
  },
  {
    id: 17,
    name: 'Waiting author to proceed to layout after copy editing',
    short: 'Awaiting Post Copy',
    abbreviation: 'APCP',
  },
  { id: 18, name: 'In layout', short: 'In Layout', abbreviation: 'LAYO' },
  { id: 19, name: 'Pre-layout evaluation', short: 'Pre-layout Eval', abbreviation: 'PLEV' },
  {
    id: 20,
    name: 'Published with Post peer-review',
    short: 'Post-peer Review',
    abbreviation: 'PPRV',
  },
  { id: 21, name: 'Deleted', short: 'Deleted', abbreviation: 'DELT' },
  {
    id: 22,
    name: 'Waiting for approval by the E-i-C',
    short: 'E-i-C Approval',
    abbreviation: 'EICA',
  },
  {
    id: 23,
    name: 'Returned to author from Editor in Unassigned state',
    short: 'Unassigned Ret.',
    abbreviation: 'UNRE',
  },
  { id: 24, name: 'In Preproduction', short: 'Preproduction', abbreviation: 'PREP' },
  {
    id: 25,
    name: 'Waiting for Guest Editor assignment',
    short: 'Guest Ed. Assign',
    abbreviation: 'GEAS',
  },
  {
    id: 26,
    name: 'Returned to author from Special Issue Editor in Unassigned state',
    short: 'Spec. Issue Ret.',
    abbreviation: 'SIER',
  },
  {
    id: 27,
    name: 'Returned to author from Collection Lead Editor in Unassigned state',
    short: 'Coll. Lead Ret.',
    abbreviation: 'CLER',
  },
  {
    id: 28,
    name: 'Waiting for approval by the Collection Editor',
    short: 'Coll. Ed. Approval',
    abbreviation: 'CEAP',
  },
  {
    id: 29,
    name: 'Approved by the Collection Editor',
    short: 'Coll. Ed. Approved',
    abbreviation: 'CEAP',
  },
  {
    id: 30,
    name: 'Rejected by the Collection Editor',
    short: 'Coll. Ed. Rejected',
    abbreviation: 'CERE',
  },
  { id: 31, name: 'Added to collection', short: 'Collection Added', abbreviation: 'COLL' },
  { id: 32, name: 'Draft', short: 'Draft', abbreviation: 'DRAF' },
] as const;

export type StateNameEntry = (typeof stateNames)[number];

export type StateName = (typeof stateNames)[number]['name'];

export type StateAbbreviation = (typeof stateNames)[number]['abbreviation'];
export type StateShort = (typeof stateNames)[number]['short'];
