import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { EchoService } from '@app/core/services/echo/echo.service';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@core/services/app-config';
import { Router } from '@angular/router';
import { roleMapping } from '@app/core/services/all-users.service';
import { changeVersionSubject } from '../../../../y-prosemirror-src/plugins/sync-plugin';
import { DispatcherMessage, NotificationResponse } from './models';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { IArticleVersion } from '@app/editor/utils/interfaces/articleVersion.interface';
import { CONSTANTS } from '@app/core/services/constants';
import { AuthService } from '@app/core/services/auth.service';
import { User } from '@app/core/models/article.models';
import { Role } from '@app/core/models/user.model';
import { VersionChange } from '@app/core/models/version.models';
import { ArticlePJSInfo } from '@app/core/models/pjs.models';
import { ResponseWithMetadata } from '@app/core/models/http.models';
import { ImportJatsService } from '@app/editor/dialogs/export-options/jatsXML/importAsJatsXML.service';

export interface notificationEvent {
  date: number;
  event: string;
  status: string;
  eventId: string;
  new: boolean;
  type?: string;
  task_id?: string;
  link?: string;
  metaData?: any;
  error?: string;
  data?: any;
  docName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notificationsBehaviorSubject = new ReplaySubject<notificationEvent[]>();
  localNotifications: notificationEvent[] = [];
  allNotifications: notificationEvent[] = [];

  private _articleUpdated$ = new BehaviorSubject<ArticlePJSInfo>(null);

  get articleUpdated$(): Observable<ArticlePJSInfo> {
    return this._articleUpdated$.pipe(filter((a) => !!a));
  }

  addLocalNotification(event: notificationEvent) {
    this.localNotifications.push(event);
    this.passNotifications();
  }

  getAllNotifications() {
    this.http
      .get(`${this.config.apiUrl}/event-dispatcher/tasks`)
      .pipe(
        map((data: any[]) => {
          const oldNotifications = this.getOldNotificationsIds();
          this.allNotifications = data.map((task) => {
            const date = new Date(task.created_at).getTime();
            const isNew = !oldNotifications.includes(task.task_id);
            const notification: notificationEvent = {
              date,
              event: task.type === 'pdf.export' ? 'PDF export' : task.type,
              docName: task.data?.data?.article_title,
              status: task.status,
              eventId: task.task_id,
              new: isNew,
              data: task.data?.data || null,
            };
            if (task.type === 'pdf.export' && task.status === 'DONE') {
              notification.link = task.data.data ? task.data.data.url : task.data.url;
            }
            return notification;
          });
          this.passNotifications();
        })
      )
      .subscribe();
  }

  viewNotification(event: notificationEvent) {
    if (event.link) {
      if (
        event.link == 'open_jats_render_errors' ||
        event.link == 'open_pdf_render_errors' ||
        event.link == 'open_jats_render_style_warnings'
      ) {
        const errors = event.metaData.slice(0, 4);
        this.serviceShare.openJatsErrorsDialog(errors);
      } else {
        if (event.event == 'DOCX Import') {
          this.http.get(event.link, { responseType: 'blob' }).subscribe(
            (blob) => {
              const reader = new FileReader();
              reader.onload = () => {
                const xmlText = reader.result as string;

                const parser = new DOMParser();
                const xmlDocument = parser.parseFromString(xmlText, 'text/xml');

                this.serviceShare.createNewArticle(xmlDocument, this.importJatsService);
              };

              reader.readAsText(blob);
            },
            (error) => {
              console.error('File download failed:', error);
            }
          );
        } else {
          window.open(event.link);
        }
      }
    } else if (event.error && event.event === 'PDF export') {
      this.serviceShare.openJatsErrorsDialog([event.error]);
    }
    let eventid = event.eventId;
    this.setEventAsOld(eventid);
  }

  constructor(
    private serviceShare: ServiceShare,
    private http: HttpClient,
    private readonly echoService: EchoService,
    private router: Router,
    private snackBar: SnackbarService,
    private authService: AuthService,
    private importJatsService: ImportJatsService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.authService.currentUser$.subscribe((user) => {
      const token = authService.getToken();
      this.echoService.setAuthToken(token);
      if (user) {
        this.joinChannels(user);
      }
    });
  }

  private joinChannels(user: User): void {
    const userNotificationChannel = `task_manager:private-notifications.${user.id}`;
    const commonNotificationChannel = `task_manager:private-notifications.all`;
    const userSystemMessagesChannel = `task_manager:private-system-notifications.${user.id}`;

    this.echoService
      .joinChannel(userNotificationChannel)
      .listen('.TaskCreatedEvent', (data) => this.handleTaskUpdatesEvents(data))
      .listen('.TaskUpdateEvent', (data) => this.handleTaskUpdatesEvents(data));

    this.echoService
      .joinChannel(commonNotificationChannel)
      .listen('.article:updated', (data) => this.handleArticleUpdatedEvent(data))
      .listen('.user:updated', (data) => this.handleUserUpdatedEvent(data));

    this.echoService
      .joinChannel(userSystemMessagesChannel)
      .listen('.SystemNotificationEvent', (data: NotificationResponse<DispatcherMessage>) => {
        this.handleUserSystemMessage(data);
      });
  }

  private handleUserSystemMessage(notification: NotificationResponse<DispatcherMessage>): void {
    const { type, message, config } = notification.data;
    this.snackBar.showMessage(message, type, config || {});
  }

  private getOldNotificationsIds(): string[] {
    let oldNotifications = sessionStorage.getItem('oldevents');
    if (oldNotifications) {
      return JSON.parse(oldNotifications);
    } else {
      return [];
    }
  }

  private handleUserUpdatedEvent(notification: any): void {
    // Handle the 'user:updated' event logic
    console.log('Notification [user:updated]', notification);
    if (
      this.serviceShare.YdocService.userInfo &&
      this.serviceShare.YdocService.articleData &&
      this.serviceShare.YdocService.userInfo.data.id === notification.data.id
    ) {
      this.serviceShare.ArticlesService.getArticleDomainPolicies(notification.data.uuid).subscribe({
        next: (res) => this.serviceShare.EnforcerService.policiesChangeSubject.next(res),
        error: (err) => console.error(err),
      });
    }
  }

  private handleArticleUpdatedEvent(notification: ResponseWithMetadata<ArticlePJSInfo>): void {
    console.log('Notification [article:updated]', notification);
    this._articleUpdated$.next(notification.data);

    // Check if the article UUID matches the current article being worked on
    if (notification.data.uuid === this.serviceShare.YdocService.articleData?.uuid) {
      // Check if the article name has changed and update accordingly
      if (this.serviceShare.YdocService.articleData.name !== notification.data.name) {
        this.serviceShare.YdocService.articleData.name = notification.data.name;
        this.serviceShare.YdocService.articleData.updated_at = notification.data.updated_at;
      } else {
        if (
          this.serviceShare.YdocService.articleData.ydoc_version !=
            notification.data.ydoc_version &&
          this.serviceShare.maintenancePageMode
        ) {
          document.location.reload();
        }
        if (
          this.serviceShare.YdocService.articleData.ydoc_version !=
            notification.data.ydoc_version &&
          this.serviceShare.maintenancePageMode
        ) {
          document.location.reload();
        }
        // Update collaborators and handle version changes
        this.serviceShare.YdocService.articleData.collaborators =
          (notification.data.collaborators as any).data || notification.data.collaborators; // TODO - mind to add precise definitions when refactoring
        const collaboratorsFromEvent = (
          (notification.data.collaborators as any).data || notification.data.collaborators
        ) // TODO - mind to add precise definitions when refactoring
          .filter((c) => c.role !== 'article_admin');

        if (collaboratorsFromEvent) {
          let changeInVersionArr = false;
          let collaborators = JSON.parse(
            JSON.stringify(this.serviceShare.YdocService.getCollaborators())
          );

          // Map collaborators to authorListData structure
          let authorsList = collaboratorsFromEvent
            .map((user: any) => {
              if (user.role === Role.author || user.role === Role.commenter) {
                if (user.user_id == null) {
                  return {
                    authorId: user.user_email,
                    authorEmail: user.user_email,
                  };
                } else {
                  return {
                    authorId: user.user_id,
                    authorEmail: user.user_email,
                  };
                }
              }
            })
            .filter((c: any) => c !== undefined);

          // Handle version and collaborators updates
          const userFromCollaborators = collaboratorsFromEvent.find(
            (u) => u.user_id === this.serviceShare.YdocService.currUser.id
          );
          if (
            userFromCollaborators &&
            this.serviceShare.compareObjects(
              userFromCollaborators.allowed_article_versions,
              this.serviceShare.YdocService.currUser.allowed_article_versions
            )
          ) {
            this.serviceShare.YdocService.currUser.allowed_article_versions =
              userFromCollaborators.allowed_article_versions;
            changeInVersionArr = true;
          }

          // Update the collaborators list
          if (collaborators.length > collaboratorsFromEvent.length) {
            collaborators = collaborators.filter((c) =>
              collaboratorsFromEvent.find((coll) => coll.user_id === c.id)
            );
          }

          collaboratorsFromEvent.forEach((c) => {
            const collaborator = collaborators.find((cr) => cr.id === c.user_id);
            if (!collaborator) {
              collaborators.push({
                name: c.user_name,
                email: c.user_email,
                first_name: c.user_first_name,
                last_name: c.user_last_name,
                role: c.role,
                is_owner: c.is_owner,
                position: c.position,
                access: c.is_owner ? 'Owner' : roleMapping[c.role],
                affiliations:
                  c.affiliations?.data?.map((a: any) => ({
                    city: a.city,
                    country: a.country,
                    affiliation: a.affiliation,
                  })) || [],
                type: c.type,
                auth_role: c.auth_role,
                settings: c.settings,
                hide_me_from_user: c.hide_me_from_user,
                hide_my_comments_from_user: c.hide_my_comments_from_user,
                allowed_article_versions: c.allowed_article_versions,
                is_co_author: c.is_co_author,
                role_label: c.role_label,
                id: c.user_id,
              });
            } else {
              // Update existing collaborator information
              collaborator.role = c.role;
              collaborator.access = c.is_owner ? 'Owner' : roleMapping[c.role];
              collaborator.allowed_article_versions = c.allowed_article_versions;
              collaborator.hide_my_comments_from_user = c.hide_my_comments_from_user;
              collaborator.hide_me_from_user = c.hide_me_from_user;
              collaborator.settings = c.settings;
              collaborator.auth_role = c.auth_role;
              collaborator.type = c.type;
              collaborator.position = c.position;
              collaborator.is_owner = c.is_owner;
              collaborator.last_name = c.user_last_name;
              collaborator.first_name = c.user_first_name;
              collaborator.email = c.user_email;
              collaborator.name = c.user_name;
              collaborator.role_label = c.role_label;
              collaborator.is_co_author = c.is_co_author;
            }
          });

          // Handle current user's access and article versioning
          if (this.serviceShare.YdocService.currUser.access !== 'Owner') {
            this.serviceShare.YdocService.currUser = collaborators.find(
              (user) => user.id === this.serviceShare.YdocService.currUser.id
            );
          }

          if (!this.serviceShare.YdocService.currUser) {
            this.serviceShare.openNotAddedToEditorDialog();
            return;
          }

          if (
            this.serviceShare.compareObjects(
              this.serviceShare.YdocService.getCollaborators(),
              collaborators
            )
          ) {
            this.serviceShare.YdocService.collaborators?.set('collaborators', {
              collaborators: collaborators,
            });
          }

          if (
            this.serviceShare.compareObjects(
              this.serviceShare.YdocService.getAuthorsList(),
              authorsList
            )
          ) {
            this.serviceShare.YdocService.collaborators?.set('authorsList', authorsList);
          }

          this.serviceShare.YdocService.checkHiddenCommentsAndUsers();

          if (changeInVersionArr) {
            this.handleVersionChange();
          }
        }
        this.serviceShare.YdocService.articleData.metadata = notification.data.metadata;
        this.handlePoliciesUpdate(notification.data.uuid);
      }
    }
  }

  private handleVersionChange() {
    const roomName = this.serviceShare.YdocService.articleData?.uuid;
    if (
      this.serviceShare.YdocService.currUser.allowed_article_versions.includes(CONSTANTS.LATEST)
    ) {
      this.serviceShare.YdocService.versionSubject.next(VersionChange.reconnect);
      changeVersionSubject.next('returnToNewest');
      this.router.navigate([roomName]);
      this.serviceShare.YdocService.destroyVersionDoc();
      this.serviceShare.YdocService.shouldReconnect = true;
      this.serviceShare.YdocService.lastSelectedVersion = undefined;
    } else if (this.serviceShare.YdocService.currUser.allowed_article_versions.length > 0) {
      if (this.serviceShare.YdocService.currUser.allowed_article_versions.includes(CONSTANTS.OWN)) {
        if (this.serviceShare.YdocService.currUser.allowed_article_versions.length > 1) {
          const versions = Array.from(
            this.serviceShare.YdocService.articleVersions
          ) as IArticleVersion[];
          const lastVersion = [...versions]
            .reverse()
            .find((v) => v.users.find((u) => u.id == this.serviceShare.YdocService.currUser.id));
          const index = versions.lastIndexOf(lastVersion);

          if (index) {
            this.serviceShare.ProsemirrorEditorsService.spinSpinner();
            this.serviceShare.YdocService.versionSubject.next(VersionChange.reRender);
            this.router.navigate([roomName], {
              fragment: `${index}`,
            });
          }
        } else {
        }
      } else {
        const allowedVersions = this.serviceShare.YdocService.currUser.allowed_article_versions.map(
          (n: string) => +n
        );
        if (
          !this.serviceShare.YdocService.lastSelectedVersion ||
          !allowedVersions.includes(this.serviceShare.YdocService.lastSelectedVersion)
        ) {
          this.serviceShare.onlyOldVersions = true;
          const maxNumber = Math.max(...allowedVersions);
          this.serviceShare.ProsemirrorEditorsService.spinSpinner();
          this.serviceShare.YdocService.versionSubject.next(VersionChange.reRender);
          this.router.navigate([roomName], {
            fragment: `${maxNumber}`,
          });
        }
      }
    } else {
      this.serviceShare.onlyOldVersions = false;
      this.serviceShare.YdocService.versionSubject.next(VersionChange.reconnect);
      changeVersionSubject.next('returnToNewest');
      this.router.navigate([roomName]);
      this.serviceShare.YdocService.destroyVersionDoc();
      this.serviceShare.YdocService.shouldReconnect = true;
      this.serviceShare.YdocService.lastSelectedVersion = undefined;
    }
    this.serviceShare.YdocService.allowedVersionsStateSubject.next('change');
  }

  private handlePoliciesUpdate(articleUUID: string) {
    setTimeout(() => {
      combineLatest([
        this.serviceShare.ArticlesService.getArticleDomainPolicies(articleUUID),
        this.serviceShare.EnforcerService.policiesChangeSubject.asObservable().pipe(take(1)),
      ]).subscribe(([res, policies]) => {
        if (this.serviceShare.compareObjects(res, policies)) {
          this.serviceShare.EnforcerService.policiesChangeSubject.next(res);
        }
      });
    }, 1000);
  }

  private handleTaskUpdatesEvents(eventData) {
    console.log('handleTaskUpdatesEvents');
    if (eventData.task.type == 'pdf.export') {
      let date = new Date(eventData.task.created_at);
      let isNew = !this.getOldNotificationsIds().includes(eventData.task.task_id);
      let task: notificationEvent = {
        event: 'PDF export',
        docName: this.serviceShare.YdocService.articleData.name,
        data: eventData.task.data?.data || null,
        date: date.getTime(),
        eventId: eventData.task.task_id,
        status: eventData.task.status,
        new: isNew,
      };
      if (eventData.task.status == 'DONE') {
        let url = eventData.task.data.data.url;
        task.link = url;
      } else if (eventData.task.status == 'FAILED') {
        task.metaData = [eventData.task.data.error];
        task.link = 'open_pdf_render_errors';
      }
      if (this.allNotifications.findIndex((n) => n.eventId == task.eventId) != -1) {
        this.updateEventData(task);
      } else {
        this.newNotificationEvent(task);
      }
    }
  }

  private passNotifications() {
    let oldNotifications = this.getOldNotificationsIds();
    this.allNotifications.forEach((notification) => {
      if (oldNotifications.includes(notification.eventId)) {
        notification.new = false;
      } else {
        notification.new = true;
      }
    });
    this.localNotifications.forEach((notification) => {
      if (oldNotifications.includes(notification.eventId)) {
        notification.new = false;
      } else {
        notification.new = true;
      }
    });
    let allNotificationArr = [...this.allNotifications, ...this.localNotifications];
    this.notificationsBehaviorSubject.next(allNotificationArr);
  }

  private updateEventData(event: notificationEvent) {
    this.allNotifications = this.allNotifications.map((task) => {
      if (task.eventId == event.eventId) {
        return event;
      }
      return task;
    });
    this.passNotifications();
  }

  private newNotificationEvent(event: notificationEvent) {
    this.allNotifications.push(event);
    this.passNotifications();
  }

  private setEventAsOld(eventid: string) {
    let oldNotifications: any = sessionStorage.getItem('oldevents');
    let oldevents: string[];
    if (!oldNotifications) {
      oldevents = [];
    } else {
      oldevents = JSON.parse(oldNotifications);
    }
    if (!oldevents.includes(eventid)) {
      oldevents.push(eventid);
    }
    sessionStorage.setItem('oldevents', JSON.stringify(oldevents));
    setTimeout(() => {
      this.passNotifications();
    }, 10);
  }
}
