import { Injectable } from '@angular/core';
import { BehaviorSubject, from, NEVER, Observable, of, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { AuthService } from '@app/core/services/auth.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import JwtEnforcer from '../lib/JwtEnforcer';
import { getModel } from '../models/index';
import { getRequestKey } from './helpers';
import { ACL } from '../interfaces';
import { CasbinGlobalObjectsService } from './casbin-global-objects.service';
import { PermissionObject, PermissionObjectAction } from '@app/core/models/auth.model';

@Injectable({ providedIn: 'root' })
export class EnforcerService {
  private _acls: Observable<ACL[] | null> | null = null;
  readonly policyUpdateAction = 'updated_policies';

  userPolicies = [];
  articlePolicies = [];

  private enforcer: JwtEnforcer | null = null;
  private sub: Subscription | null = null;

  private false = of(false);

  policyUpdate$ = new BehaviorSubject(null);
  enforcedEndpoints: any = {};

  policiesFromBackend: any;
  constructor(
    private serviceShare: ServiceShare,
    private authService: AuthService,
    private casbinGlobalObjectService: CasbinGlobalObjectsService
  ) {
    this.false.subscribe((d) => false);
    this.triggerUpdatePolicy();
    this.serviceShare.shareSelf('EnforcerService', this);
    this.authService.currentUser$.subscribe((data) => {
      if (data) {
        this.policiesChangeSubject.next({ data });
      }
    });
  }

  policiesChangeSubject = new BehaviorSubject(null);
  userInfo: any;
  triggerUpdatePolicy() {
    this.policiesChangeSubject.subscribe((res) => {
      if (res) {
        if (res.data) {
          this.userInfo = res.data;
          this.policiesFromBackend = this.mapPolicies(res.data.permissions);
          this.updateAllPolicies(this.policiesFromBackend);
          this.userPolicies = JSON.parse(JSON.stringify(this.policiesFromBackend));
        } else {
          this.policiesFromBackend = JSON.parse(JSON.stringify(this.userPolicies));
          this.articlePolicies = this.mapPolicies(res);
          this.policiesFromBackend.push(...this.articlePolicies);
          this.updateAllPolicies(this.policiesFromBackend);
        }
      }
    });
  }

  enforceRequest = (obj: string, act: string) => {
    this.enforceAsync(obj, act).subscribe((access) => {
      this.enforcedEndpoints[getRequestKey('', obj, act)] = { access };
      this.policyUpdate$.next(this.enforcedEndpoints);
    });
  };

  enforceAsync = (obj: string, act: string) => {
    if (!this.enforcer) {
      return of(false);
    }
    return from(this.enforcer.enforcePromise(this.userInfo.id, obj, act));
  };

  enforceSync(obj: string, act: string): boolean {
    return this.enforcer.enforceSync(this.userInfo.id, obj, act);
  }

  mapPolicies = (policiesFromBackend: any) => {
    const allParsedPolicies: any[] = [];
    const parseRecursive = (array: any[]) => {
      if (array.length > 0 && typeof array[0] == 'string') {
        const obj = array[2]; // == "comments(*, *)" ? "comments(*)" : array[2] == "comments(*, isCommentOwner())" ? "comments(isCommentOwner())" : array[2];

        const policy = {
          prefix: array[0],
          sub: array[1],
          obj,
          act: array[3],
          eft: array[4],
        };

        if (policy.sub == 'commenter' || policy.sub == 'reader' || policy.sub == 'writer') {
          this.serviceShare.userRole = policy.sub;
        }

        if (policy.obj == '*' && policy.act == '.*' && policy.eft == 'allow') {
          allParsedPolicies.push(policy);
        } /* else if (policy.obj == '/articles/sections') {
          policy.eft = 'deny'
          allParsedPolicies.push(policy);
        }else if (policy.obj == '/articles/*') {
          policy.eft = 'deny'
          allParsedPolicies.push(policy);
        } */ else {
          allParsedPolicies.push(policy);
        }
        //allParsedPolicies.push(policy);
      } else {
        array.forEach((el, i) => {
          if (typeof el != 'string') {
            parseRecursive(el);
          }
        });
      }
    };
    parseRecursive(policiesFromBackend);

    return allParsedPolicies;
  };

  getKey(obj: string, act: string) {
    return `${obj} + ${act}`;
  }

  updateAllPolicies(policiesFromBackend: any) {
    this.load(of(policiesFromBackend)).then((done) => {
      this.notifyForUpdate();
    });
  }

  loadedPolicies = false;
  notifyForUpdate() {
    this.loadedPolicies = true;
    this.enforcedEndpoints = {};
    this.policyUpdate$.next(this.policyUpdateAction);
  }

  load(acls: Observable<ACL[]>) {
    return new Promise((resolve) => {
      this.unload();
      this.sub = acls
        .pipe(
          concatMap((acls) => {
            if (!acls) {
              return NEVER;
            }
            const enforcer = new JwtEnforcer(
              acls,
              this.authService,
              this.casbinGlobalObjectService
            );
            return from(enforcer.setup(getModel()));
          })
        )
        .subscribe((enforcer) => {
          this._acls = acls;
          this.enforcer = enforcer;
          resolve(true);
        });
    });
  }

  unload(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.enforcer = null;
  }

  getAllowedActions<T extends PermissionObject>(permissionObject: T): PermissionObjectAction<T>[] {
    if (!this.enforcer || !this.userInfo || !this.policiesFromBackend) {
      return [];
    }

    const relevantPolicies = this.policiesFromBackend.filter(
      (policy) =>
        (policy.obj === permissionObject || policy.obj.startsWith(`${permissionObject}(*`)) &&
        policy.eft === 'allow'
    );

    const allowedActions: PermissionObjectAction<T>[] = [];

    relevantPolicies.forEach((policy) => {
      const actions = this.parseActions(policy.act);
      actions.forEach((action) => {
        if (this.enforceSync(permissionObject, action)) {
          allowedActions.push(action as PermissionObjectAction<T>);
        }
      });
    });

    // Remove duplicates and return
    return [...new Set(allowedActions)];
  }

  getAllowedActionsPerArticle<T extends PermissionObject>(
    permissionObject: T,
    articleUuid: string
  ): PermissionObjectAction<T>[] | [] {
    if (!this.enforcer || !this.userInfo || !this.policiesFromBackend) {
      return [];
    }

    const relevantPolicies = this.policiesFromBackend.filter(
      (policy) =>
        policy.obj === permissionObject ||
        policy.obj.startsWith(`${permissionObject}(*`) ||
        (policy.obj === permissionObject &&
          policy.obj.includes(articleUuid) &&
          policy.sub === this.userInfo.id)
    );

    const allowedActions: PermissionObjectAction<T>[] = [];

    if (relevantPolicies.length > 0) {
      relevantPolicies.forEach((policy) => {
        const actions = this.parseActions(policy.act);
        actions.forEach((action) => {
          if (this.enforceSync(permissionObject, action)) {
            allowedActions.push(action as PermissionObjectAction<T>);
          }
        });
      });
    } else {
      return this.getAllowedActions(permissionObject);
    }

    // Remove duplicates and return
    return [...new Set(allowedActions)];
  }

  getObjectPermissions<T extends PermissionObject>(
    objects: T[]
  ): Record<T, PermissionObjectAction<T>[]> {
    if (!this.enforcer || !this.userInfo || !this.policiesFromBackend) {
      return {} as Record<T, PermissionObjectAction<T>[]>;
    }

    const result = {} as Record<T, PermissionObjectAction<T>[]>;

    objects.forEach((obj) => {
      const actions = this.getAllowedActions(obj);
      if (actions.length > 0) {
        result[obj] = actions;
      }
    });

    return result;
  }

  private parseActions(actionString: string): string[] {
    // Remove parentheses and split by '|'
    return actionString.replace(/[()]/g, '').split('|').filter(Boolean);
  }
}
