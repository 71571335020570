import { Component, OnInit, Input } from '@angular/core';
import { basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { TreeService } from '@app/editor/meta-data-tree/tree-service/tree.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { ImportWizardDialogData, SectionContent } from '../../jats.models';

@Component({
  selector: 'app-sections-tree',
  templateUrl: './sections-tree.component.html',
  styleUrls: ['./sections-tree.component.scss'],
})
export class SectionTreeComponent implements OnInit {
  @Input() data: ImportWizardDialogData;

  articleSectionsStructure: SectionContent[];

  constructor(
    public treeService: TreeService,
    private ydocService: YdocService
  ) {}

  ngOnInit(): void {
    const articleSections = JSON.parse(
      JSON.stringify(this.ydocService.articleStructureMap.get('articleSectionsStructure'))
    ) as basicArticleSection[];

    this.findChildSection(articleSections);

    this.articleSectionsStructure = this.data.sectionsContent;
  }

  findChildSection(structure: basicArticleSection[]): void {
    structure.forEach((section) => {
      if (section.active && section.label != 'Title') {
        const jatsSection = this.data.allJatsSections.find(
          (sec) => sec.sectionID == section.sectionID
        );
        if (jatsSection) {
          section.label = jatsSection.title;
        }
      }
      if (section.children.length > 0) {
        this.findChildSection(section.children);
      }
    });
  }
}
