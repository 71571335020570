import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { YdocService } from '@app/editor/services/ydoc.service';
import { SectionInListComponent } from './section/section-in-list.component';
import { basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { SectionContent } from '../../../jats.models';

@Component({
  selector: 'app-list-recursive',
  templateUrl: './list-recursive.component.html',
  styleUrls: ['./list-recursive.component.scss'],
})
export class ListRecursiveComponent {
  @Input() articleSectionsStructure: SectionContent[];
  @Input() nestedList!: boolean;
  @Input() firstNested!: boolean;
  @Input() listData!: {
    expandParentFunc: () => void;
    listDiv: HTMLDivElement;
    listInstance: SectionInListComponent;
  };
  @Input() listParentId?: string;
  @Output() articleSectionsStructureChange = new EventEmitter<unknown>();

  constructor(
    private ydocService: YdocService,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  hasSection(node: basicArticleSection): boolean {
    return !!this.ydocService.getSectionByID(node.sectionID);
  }

  isComplex(node: basicArticleSection): boolean {
    return this.ydocService.getSectionByID(node.sectionID).type == 'complex';
  }
}
